$actions-top: 50px;

.MachineFillingInfo {
  display: flex;
  flex-direction: row;
  gap: var(--space-3xl);
  padding: 0;
  width: 100%;

  .leftSide {
    width: calc(50% - var(--space-3xl) / 2);
  }

  .rightSide {
    width: calc(50% - var(--space-3xl) / 2);
  }
}

.actions {
  position: absolute;
  bottom: $actions-top;
  right: 0;
  align-items: center;

  border-radius: var(--control-radius-round-m) 0 0 var(--control-radius-round-m);
  padding: var(--space-m);

  background: var(--bg-main-secondary);
}

