$maxWidth-primary: 430px;
$maxWidth-secondary: 274px;
$maxWidth-form: 744px;

.userForm {
  display: flex;
  flex-direction: column;
  gap: var(--space-xl);

  max-width: $maxWidth-form;

  .actions {
    display: flex;
    gap: var(--space-3xl);

    .primary {
      flex: 1;
      max-width: $maxWidth-primary;
    }

    .secondary {
      flex: 1;
      max-width: $maxWidth-secondary;
    }
  }
}