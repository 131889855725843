.productBaseList {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-5xl);

  .actions {
    display: flex;
    gap: var(--space-xl);
    flex-wrap: wrap;

    .search {
      min-width: 250px;
      flex: 1;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    gap: var(--space-l);

    .addButton {
      justify-content: center;
    }
  }
}
