$cell-header-height: 24px;

.head {
  .row {
    flex-direction: row;
    gap: var(--space-xs);

    display: flex;

    box-sizing: border-box;
    padding: var(--space-m) var(--space-m) 0 var(--space-2xl);

    .cellHeader {
      height: $cell-header-height;

      text-align: start;
    }

    .cellNumberCell {
      box-sizing: border-box;
      width: var(--space-2xl);
      padding-left: 0;

      text-align: center;
    }

    .brandCell {
      min-width: 28%;
    }

    .productCell {
      min-width: 44.1%;
    }

    .priceCell {
      flex: 1;
      width: 100%;
    }
  }
}
