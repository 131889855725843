$MachineVendistaSettingHeight: 80px;
$errorStatusWidth: 10px;
$errorStatusHeight: 10px;
$editModalWidth: 512px;

.MachineVendistaSetting {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  display: flex;

  border-radius: var(--control-radius-round-m);
  height: $MachineVendistaSettingHeight;
  padding: var(--space-m) var(--space-xl);

  color: var(--typo-main-primary);

  .errorStatus {
    background-color: var(--typo-status-alert);
    width: $errorStatusWidth;
    height: $errorStatusHeight;
    border-radius: 50%;
  }
}

.editModal {
  width: $editModalWidth;
}
