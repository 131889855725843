.contentCard {
  background: var(--bg-main-tertriary);
  box-sizing: border-box;
  padding: var(--space-s) var(--space-l);
  gap: var(--space-l);
  align-items: flex-start;
}

.emptyContentCard {
  padding: var(--space-5xl) 0;
  gap: var(--space-s);
  background: var(--bg-main-tertriary);
}

.iconContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ellipse {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--bg-main-quaternary);
}

.iconSearch {
  position: absolute;
  color: var(--typo-main-primary);
}
