.MachineSettings {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  margin-top: var(--space-2xl);

  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--space-l) var(--space-2xl);

    .actions {
      display: flex;
      justify-content: flex-end;
      padding-top: var(--space-2xl);
      margin-top: var(--space-l);
    }

    .cardCollapse {
      width: 100%;
      display: flex;
      flex-direction: column;

      .leftSide {
        display: flex;
        gap: var(--space-2xl);
        align-items: center;

        .cardInfo {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.horizontalContainer {
  display: flex;
}
