.MachineFillingEdit {
  display: flex;
  padding: var(--space-l);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-m);
  align-self: stretch;

  border-radius: var(--control-radius-round-m);
  background: var(--bg-main-secondary);

  width: 100%;
}

.MachineFillingEditListItem {
  display: flex;
  padding: var(--space-s) var(--space-l);
  align-items: center;
  gap: var(--space-s);
  align-self: stretch;

  border-radius: var(--control-radius-round-m);
  background: var(--bg-main-tertriary);
}

.numberCell {
  width: 3%;

  .cellNumberCard {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: var(--control-radius-l);

    background: var(--bg-main-quaternary);

    .text {
      color: var(--typo-color)
    }
  }
}

.brandCell {
  width: 31%;
}

.ingredientLineCell {
  width: 31%;
}

.ingredientCell {
  width: 35%;
}

.combobox {
  width: 31%;
}

.header {
  width: 100%;
  padding: 0 var(--space-l);
}

.actions {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
