$TelegramLogoWidth: 40px;
$TelegramLogoHeight: 46px;

.TelegramNotificationSettings {
  .TelegramNotification {
    border-radius: var(--control-radius-round-m);
    padding: calc(var(--space-m) - 1px) var(--space-xl) var(--space-m) calc(var(--space-xl) - 1px);

    background: var(--bg-main-secondary);

    .TelegramLogo {
      width: $TelegramLogoWidth;
      height: $TelegramLogoHeight;
    }
  }

}
