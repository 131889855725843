.dosageCell {
  display: flex;
  flex-direction: row;
  gap: var(--space-xs);

  .fieldGroup {
    width: 200px;
  }
}

.cellNumbersWrapper {
  width: 32px;
}

.cellNumbersCell {
  display: flex;
  flex-direction: column;
  gap: var(--space-2xs);

  .cellNumberCard {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: var(--control-radius-l);

    background: var(--bg-main-quaternary);

    .text {
      color: var(--typo-color)
    }
  }
}
