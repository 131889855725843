$TableHeaderHeight: 44px;
$TableHeaderTop: 0px;

.TableHeader {
  position: sticky;
  z-index: 0;
  top: $TableHeaderTop;
  justify-content: space-between;
  align-items: center;

  display: flex;

  border-bottom: 1px solid var(--bg-border-secondary);
  border-radius: var(--control-radius-round-s) var(--control-radius-round-m) 0 0;
  box-sizing: border-box;
  height: $TableHeaderHeight;
  padding: var(--space-3xs) 0 var(--space-3xs) calc(var(--space-2xs) + 1px);

  background: var(--bg-main-secondary);
}

.checkboxContainer {
  min-width: var(--checkboxContainer-width);

  text-align: center;
  align-self: center;
}
