$loader-width: 500px;

.SnackBrandListItem {

  .brandCard {
    padding: 0 var(--space-2xs);
    border-radius: var(--control-radius-xs);
    height: var(--space-xl);

    cursor: pointer;

    .iconArrow {
      color: var(--control-ghost-typo-typo);
    }

    &:hover {
      background: var(--control-ghost-bg-bg-hover);
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: var(--space-2xs);

    overflow: hidden;
    max-height: 0;

    transition: max-height 0.3s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.5, 1);

    &_isOpen {
      max-height: var(--collapse-body-max-height);
    }

    &_withBorder {
      position: relative;
      border-left: 1px solid var(--control-default-border-border);
      margin-left: var(--space-m);
    }

    .loader {
      height: var(--space-xl);
      min-width: $loader-width;
      border-radius: var(--control-radius-xs);
    }
  }
}
