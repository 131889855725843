.OutletTable {

  .header {
    height: 48px;
    padding: var(--space-xs) var(--space-l);

    padding-left: 40px;

    border-radius: var(--control-radius-round-m);
    background: var(--bg-main-primary);
  }

  .nameCell {
    width: 358px;
    padding-left: var(--space-m);
  }

  .salesCell {
    width: 500px;

    .iconNewCheck {
      color: var(--typo-main-primary);
    }
  }

  .outlet {

    display: flex;
    flex-direction: column;
    //padding-right: 20px;

    border-radius: var(--control-radius-round-l);
    border: 1px solid var(--point-card-border-default);
    background: var(--bg-table-primary);

    overflow: hidden;

    .outletInfo {
      background-color: var(--point-сard-bg-default);

      &.success {
        background-color: var(--point-сard-bg-default);
      }

      &.warning {
        background-color: var(--point-сard-bg-default);
      }

      &.alert {
        background-color: var(--point-сard-bg-default);
      }

       &.active {
         background-color: var(--point-card-bg-default-active);

         &.success {
           background-color: var(--point-сard-bg-success-active);
         }

         &.warning {
           background-color: var(--point-card-bg-warning-active);
         }

         &.alert {
           background-color: var(--point-card-bg-alert-active);
         }
       }
    }

    .leftSide {
      background: var(--point-card-border-default);

      height: 94px;

      display: flex;
      width: 40px;
      justify-content: center;
      align-items: center;
      align-self: stretch;

      color: var(--control-clear-typo-typo);

      cursor: pointer;
    }

    .groupContent {
      height: 94px;

      display: flex;
      padding:  0;
      justify-content: space-between;
      align-items: center;
      flex: 1 0 0;
    }

    &.alert {
      border: 1px solid var(--point-сard-border-alert);

      .leftSide {
        background: var(--point-сard-border-alert);
      }
    }

    &.warning {
      border: 1px solid var(--point-card-border-warning);

      .leftSide {
        background: var(--point-card-border-warning);
      }
    }

    &.success {
      border: 1px solid var(--point-сard-border-success);

      .leftSide {
        background: var(--point-сard-border-success);
      }
    }
  }
}

.actions {
  padding-right: var(--space-l);
}
