$textFontSize: 14px;
$textLineHeight: 16px;
$numberTextFontSize: 26px;
$numberTextLineHeight: 32px;

.MachineListStatusBar {
  gap: var(--space-s);

  display: flex;

  .card {
    justify-content: center;
    align-items: center;
    gap: var(--space-s);
    flex: 1;

    display: flex;

    border-radius: var(--control-radius-round-m);
    background: var(--bg-table-primary);
    padding: var(--space-m);

    .text {
      font-size: $textFontSize;
      line-height: $textLineHeight;
      white-space: pre-wrap;
    }

    .numberText {
      font-size: $numberTextFontSize;
      line-height: $numberTextLineHeight;
    }

    &.selected {
      background-color: var(--bg-main-select);
    }
  }

  .loadingCard {
    border-radius: var(--control-radius-round-m);
  }
}