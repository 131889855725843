$MachineHomePageMaxWidth: 2000px;
$MachineHomePageMinWidth: 1100px;
$MachineHomePageDiff: 160px;

.MachineHomePage {
  margin-top: var(--space-2xl);
  max-width: $MachineHomePageMaxWidth;
  height: calc(100% - $MachineHomePageDiff);
  min-width: $MachineHomePageMinWidth;

  .leftSide {
    width: calc(50% - var(--space-3xl) / 2);
  }

  .rightSide {
    width: calc(50% - var(--space-3xl) / 2);
  }
}
