$priceCell-min-width: 178px;
$MachineSnackFillingTableRow-height: 40px;

.MachineSnackFillingTableRow {
  gap: var(--space-xs);
  align-items: center;

  display: flex;

  box-sizing: border-box;
  width: 100%;

  .cell {
    box-sizing: border-box;
    padding: 0;
  }

  .cellNumberCell {
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    display: flex;

    border-radius: var(--control-radius-m);
    border: 1px solid var(--bg-border-tertriary);
    width: var(--space-2xl);
    height: var(--space-2xl);

    background: var(--bg-main-quaternary);
  }

  .brandCell {
    min-width: 28.5%;
  }

  .productCell {
    min-width: 45%;
  }

  .priceCell {
    width: 100%;
  }
}
