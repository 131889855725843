$breadcrumbsHeight: 20px;
$breadcrumbsMobileHeight: 18px;
$machineBaseInfoContainerHeight: 32px;
$machineBaseInfoContainerMobileHeight: 22px;
$badgeHeight: 10px;
$badgeWidth: 10px;
$badgeMarginRight: 1px;

.breadcrumbs {
  height: $breadcrumbsHeight;

  &.isMobile {
    height: $breadcrumbsMobileHeight;
  }

  li:not(:last-child):hover a {
    color: var(--typo-main-brand);
  }
}

.machineBaseInfoContainer {
  height: $machineBaseInfoContainerHeight;

  &.isMobile {
    height: $machineBaseInfoContainerMobileHeight;
  }

  .badge {
    border: 0;

    height: $badgeHeight;
    width: $badgeWidth;
    margin-right: $badgeMarginRight;
  }
}
