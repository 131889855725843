.storageCardGroup {
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
  margin-top: var(--space-m);

  .text {
    margin-top: var(--space-2xs);
  }
}

.firstEl {
  margin-top: 0;
}
