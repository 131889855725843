$lineWidth: 10px;
$lineBorder-radius: 12px;
$height: 50px;

.MachineStorageCellItem {
  display: flex;
  flex-direction: column;
  align-items: center;

  .line {
    background-color: var(--bg-scheme-color-active);
    height: $height;
    width: $lineWidth;
    border-radius: $lineBorder-radius;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.slider {
  --bg-scheme-color: var(--bg-main-tertriary);
  --bg-scheme-color-hover: var(--bg-main-quaternary);
  --bg-scheme-color-active: var(--bg-main-quaternary);

  .line {
    background-color: var(--bg-scheme-color-active);
  }

  .point {
    height: $lineWidth;
    width: $lineWidth;
    border-radius: $lineBorder-radius $lineBorder-radius 0 0 ;
    background-color: var(--lineSlider-color-active);
  }

  .lineSlider {
    width: $lineWidth;
    height: var(--slider-line-size);
    background-color: var(--lineSlider-color-active);
  }
}
