.MemberKeyPage {
  height: calc(100% - 72px);

  .leftSide {
    width: calc(50% - var(--space-3xl) / 2);
  }

  .rightSide {
    width: calc(50% - var(--space-3xl) / 2);
  }
}
