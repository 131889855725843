.PromoCodeForm {
  .card {
    padding: var(--space-5xl);
    width: calc(100% - 2 * var(--space-5xl));

    border-radius: var(--control-radius-round-l);
    background-color: var(--bg-main-secondary);

    .secondaryCard {
      padding: var(--space-2xl);
      width: calc(100% - 2 *var(--space-2xl));

      border-radius: var(--control-radius-round-m);
      background-color: var(--bg-main-tertriary);

      .tertiaryCard {
        padding: var(--space-l);

        border-radius: var(--control-radius-round-m);
        background-color: var(--bg-main-quaternary);
      }
    }
  }

  .wrapper {
    width: 850px;
  }

  .fieldWrapper {
    width: 500px;
  }

  .timeCardList {
    display: flex;
    gap: var(--space-l);
    flex-wrap: wrap;

    .timeCard {
      display: flex;
      padding: var(--space-l);
      align-items: center;
      gap: var(--space-5xl);

      border-radius: var(--control-radius-round-s);
      background: var(--bg-main-tertriary);
    }
  }

}