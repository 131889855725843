@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100;
  src: url(Montserrat-Thin.woff2) format('woff2'), url(Montserrat-Thin.woff) format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  src: url(Montserrat-ExtraLight.woff2) format('woff2'), url(Montserrat-ExtraLight.woff) format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  src: url(Montserrat-Light.woff2) format('woff2'), url(Montserrat-Light.woff) format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url(Montserrat-Regular.woff2) format('woff2'), url(Montserrat-Regular.woff) format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  src: url(Montserrat-Medium.woff2) format('woff2'), url(Montserrat-Medium.woff) format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  src: url(Montserrat-SemiBold.woff2) format('woff2'), url(Montserrat-SemiBold.woff) format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: url(Montserrat-Bold.woff2) format('woff2'), url(Montserrat-Bold.woff) format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  src: url(Montserrat-ExtraBold.woff2) format('woff2'), url(Montserrat-ExtraBold.woff) format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  src: url(Montserrat-Black.woff2) format('woff2'), url(Montserrat-Black.woff) format('woff');
}

.Theme_font_gpnDefault {
  --font-primary:
    'Montserrat',
    -apple-system,
    BlinkMacSystemFont,
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  --font-mono:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
  font-family: var(--font-primary);
}
