$TableCardWithBackGroundHeaderTop: 43px;
$maxHeightTable: 200px;

.TableCardWithBackground {
  flex-direction: column;
  align-items: stretch;

  display: flex;

  border-radius: var(--control-radius-round-m);
  padding: 0 var(--space-m) var(--space-m) var(--space-m);
  padding-bottom: calc(var(--space-m) - var(--space-xs));
  scrollbar-width: none;

  background-color: var(--bg-main-secondary);

  &.isLoading {
    height: 100%;
  }

  .table {
    border: 0;
    border-collapse: initial;
    border-spacing: 0 var(--space-xs);

    max-height: $maxHeightTable;
    padding-top: calc(var(--space-xs) + 1px);
    overflow-y: scroll;

    &.withHeader {
      padding-top: calc(var(--space-m) - var(--space-xs));
    }

    .header {
      position: sticky;
      z-index: 0;
      top: $TableCardWithBackGroundHeaderTop;

      .loaderColumns {
        border-radius: var(--control-radius-round-m);
      }
    }
  }
}

.ClassicTableWithBackgroundRows {
  height: 100%;
}

.loaderContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: var(--space-xs);

  .loaderRow {
    border-radius: var(--control-radius-round-m);
    width: 100%;
  }
}
