$appMaxWidth: 1920px;
$ModalWidth: 358px;

.app {
  justify-content: center;

  display: flex;

  margin: 0 auto;
  box-sizing: border-box;
  max-width: $appMaxWidth;
  height: 100%;
  padding: var(--space-3xl) var(--space-3xl) 0 var(--space-3xl);

}

.Modal {
  min-width: $ModalWidth !important;
  width: $ModalWidth;

  .turnPhotoWarning {
    fill: var(--typo-main-primary);
  }

  .text {
    text-align: center;
  }
}

.appContent {
  flex-direction: column;
  align-items: stretch;
  flex: 1;

  display: flex;

  overflow: scroll;

  scrollbar-width: none;
}

.contextMenuAction {
  flex-direction: row;
  justify-content: space-between;

  display: flex;

  margin-bottom: var(--space-m);

  &.isMobile {
    margin-bottom: var(--space-s);
  }
}

.theme {
  margin-bottom: var(--space-m);
  height: 100%;

  background-color: var(--bg-main-body);
}
