$MachineSettingsWaterHeight: 80px;
$editModalWidth: 700px;
$editModalTextFieldWidth: 304px;

.MachineSettingsWater {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  display: flex;

  border-radius: var(--control-radius-round-m);
  height: $MachineSettingsWaterHeight;
  padding: var(--space-m) var(--space-xl);

  color: var(--typo-main-primary)
}

.editModal {
  width: $editModalWidth;

  .textField {
    width: $editModalTextFieldWidth;
  }
}
