$deleteMaxModalWidth: calc(586px - 2 * var(--space-xl));

.deleteModal {
  display: flex;
  flex-direction: column;
  background-color: var(--bg-main-primary);
  padding: var(--space-xl);
  gap: var(--space-xl);
  max-width: $deleteMaxModalWidth;

  .actions {
    display: flex;
    justify-content: right;

    .deleteButton {
      background-color: var(--control-primary-bg-bg-alert);

      &:hover {
        background-color: var(--control-primary-bg-bg-alert-hover);
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: var(--space-m);

    .confirmDeletion {
      display: flex;
      flex-direction: column;
      gap: var(--space-s);
    }
  }
}
