.ClassicTableRows {
  //max-height: 40px;
}

.checkboxContainer {
  width: var(--checkboxContainer-width);

  text-align: center;
  align-self: center;
}

.cell {
  box-sizing: border-box;

  border-bottom: 1px solid var(--bg-split-ghost);
  height: 64px;
  //padding: var(--space-m) 0;

  &:first-child {
    padding-left: var(--space-xl);
  }

  &:last-child {
    padding-right: var(--space-xl);
  }

  &.alignLeft {
    text-align: start;
  }

  &.alignRight {
    text-align: end;
  }

  &.fullWidth {
    width: 100%;
  }
}
