$MachineHomePageSalesHeight: 153px;
$salesCardWidth: 82px;
$salesCardHeight: 73px;
$salesDateCardHeight: 20px;

.MachineHomePageSales {
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: var(--space-m);

  display: flex;

  height: $MachineHomePageSalesHeight;
  padding: var(--space-m) calc(var(--space-xl) - 4px) var(--space-xl) var(--space-xl);

  color: var(--typo-main-primary);

  .salesCard {
    border: 1px solid var(--bg-border-tertriary);
    border-radius: var(--control-radius-round-m);
    box-sizing: border-box;
    width: $salesCardWidth;
    height: $salesCardHeight;
    padding: var(--space-s) var(--space-m);

    background: var(--bg-main-tertriary);

    .salesDateCard {
      align-items: center;

      border: none;
      border-radius: var(--control-radius-m);
      box-sizing: border-box;
      height: $salesDateCardHeight;
      padding: 0 var(--space-xs);

      background: var(--bg-main-quaternary);
    }
  }

  .lastSaleDate {
    flex: 1;
  }
}
