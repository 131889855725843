$OutletListStatusBar-height: 80px;

.OutletListStatusBar {
  display: grid;
  gap: var(--space-l);
  height: $OutletListStatusBar-height;
  grid-template-columns: repeat(4, 1fr);

  .card {
    display: flex;
    padding:  var(--space-l);
    justify-content: center;
    align-items: center;
    gap: var(--space-l);
    flex: 1;
    border-radius: var(--control-radius-round-l);
    background: var(--bg-table-primary);

    .text {
      white-space: pre-wrap;
    }
  }
}
