$logoWidth: 40px;
$logoHeight: 40px;
$logoContainerWidth: 64px;
$logoContainerHeight: 64px;

.IntegrationBaseInfo {
  .MainInformationAboutIntegration {
    border-radius: var(--control-radius-round-m);
    padding: var(--space-m) var(--space-xl) var(--space-m) var(--space-xl);

    background: var(--bg-main-secondary);

    .logoContainer {
      border: 1px solid var(--control-ghost-border-border);
      border-radius: var(--control-radius-round-xs);
      width: $logoContainerWidth;
      height: $logoContainerHeight;
      padding: calc(var(--space-xs) + 2px);

      cursor: pointer;
    }

    .logo {
      width: $logoWidth;
      height: $logoHeight;
    }
  }
}
