$organizationSalesTableSettingsBarClassNameHeight: 43px;
$rowHeight: 64px;
$CancelTransactionModalWidth: 620px;
$CancelTransactionModalMaxHeight: 820px;

.OrganizationSaleList {
  flex: 1;

  .modelNameText {
    width: min-content;
  }

  .rowClassName {
    box-sizing: border-box;
    height: $rowHeight;

    .iconWarning {
      color:  var(--typo-status-alert);
    }
  }

  .organizationSalesTableSettingsBarClassName {
    justify-content: flex-end;
    align-items: center;

    box-sizing: border-box;
    height: $organizationSalesTableSettingsBarClassNameHeight;

  }
}

.CancelTransactionModal{
  width: $CancelTransactionModalWidth;
  max-height: $CancelTransactionModalMaxHeight;

  .verticalContainer{
    width: 100%;

    .contentCard {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: var(--space-s);

      display: flex;

      border: 1px solid var(--bg-border-tertriary);
      border-radius: var(--control-radius-round-s);
      padding: var(--space-m) var(--space-l);

      background: var(--bg-main-tertriary);

    }
  }
}

.text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineThrough {
  text-decoration: line-through
}