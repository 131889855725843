.actions {
  display: flex;
  gap: var(--space-3xl);
  margin-bottom: var(--space-l);
}

.rowActions {
  display: flex;
  gap: var(--space-s);
}
