.LocaleMenu {
  display: flex;
  min-width: 250px;
  min-height: auto;
  padding: var(--space-xs) var(--space-s);
  justify-content: center;
  align-items: flex-start;

  border-radius: var(--control-radius-round-l);
  background-color: var(--bg-main-body);

  border: 1px solid var(--bg-border-primary);

  .iconContainer {
    width: 16px;
  }
}
