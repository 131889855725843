.card {
  padding: var(--space-5xl);
  width: calc(100% - 2 * var(--space-5xl));

  border-radius: var(--control-radius-round-l);
  background-color: var(--bg-main-secondary);

  .secondaryCard {
    padding: var(--space-2xl);
    width: calc(100% - 2 *var(--space-2xl));

    border-radius: var(--control-radius-round-m);
    background-color: var(--bg-main-tertriary);
  }
}

