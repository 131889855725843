$snackProductListItemPaddingLeft: 28px;
$snackProductListItemPaddingRight: 55px;

.SnackProductListItem {
  // TODO: фиксированные размеры исправить после изменения макетов
  padding-left: $snackProductListItemPaddingLeft;
  padding-right: $snackProductListItemPaddingRight;
  border-radius: var(--control-radius-xs);
  height: var(--space-xl);

  cursor: pointer;

  &:hover {
    background: var(--control-ghost-bg-bg-hover);
  }
}
