$MachineStorageSnackSchemeHeight: 325px;
$MachineStorageSnackSchemeWidth: 539px;

.machineStorageSnackScheme {
  box-sizing: border-box;
  min-width: $MachineStorageSnackSchemeWidth;
  min-height: $MachineStorageSnackSchemeHeight;
  max-height: $MachineStorageSnackSchemeHeight;
  padding: calc(var(--space-m) - 2px) var(--space-m) var(--space-m) var(--space-m);

  .gridContainer {
    gap: var(--space-xs);

    display: grid;

    width: 100%;
    height: 100%;

    .gridRow {
      gap: var(--space-2xs);

      display: grid;

      width: 100%;
      height: 100%;
    }
  }
}
