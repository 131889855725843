$text-container-width: 250px;
$text-container-height: 40px;
$nutritionValues-input-width: 200px;
$anotherInformation-input-width: 486px;
$containerCard-width: 1040px;
$mainInformation-input-width: 350px;
$add-button-width: 164px;
$row-gap: 19px;

.CreateSnackProduct {
  .header {
    width: $containerCard-width;
  }

  .cardContainer {
    width: $containerCard-width;
    align-items: flex-start;
    padding: var(--space-l);
    border: none;
  }

  .textContainer {
    width: $text-container-width;
    height: $text-container-height;
    align-items: center;
  }

  .content {
    .MainInformation {
      @extend .cardContainer;

      .inputContainer {
        width: $mainInformation-input-width;
      }

      .inputCodesContainer {
        width: $text-container-width;
      }
    }

    .NutritionalValues {
      @extend .cardContainer;

      .inputContainer {
        width: $nutritionValues-input-width;
      }
    }

    .AnotherInformation {
      @extend .cardContainer;

      .inputContainer {
        width: $anotherInformation-input-width;
      }

      .horizontalGap {
        row-gap: $row-gap;
      }
    }
  }

  .actions {
    width: $containerCard-width;
  }

  .button {
    width: $add-button-width;
  }
}
