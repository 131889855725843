.tagsListItem {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: var(--space-s);

  border-bottom: 1px dashed var(--bg-border-primary);
  padding-bottom: var(--space-l);
}

.tagsListItem:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
