$PromoCodeFormMinWidth: 1000px;
$wrapperWidth: 850px;
$fieldWrapperWidth: 500px;

.PromoCodeForm {
  min-width: $PromoCodeFormMinWidth;

  .card {
    padding: var(--space-5xl);
    width: calc(100% - 2 * var(--space-5xl));

    border-radius: var(--control-radius-round-l);
    background-color: var(--bg-main-secondary);

    .secondaryCard {
      padding: var(--space-2xl);
      width: calc(100% - 2 *var(--space-2xl));

      border-radius: var(--control-radius-round-m);
      background-color: var(--bg-main-tertriary);

      .tertiaryCard {
        padding: var(--space-l);

        border-radius: var(--control-radius-round-m);
        background-color: var(--bg-main-quaternary);
      }
    }
  }

  .wrapper {
    width: $wrapperWidth;
  }

  .fieldWrapper {
    width: $fieldWrapperWidth;
  }
}