.ClassicTable {
  --checkboxContainer-width: 40px;

  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  border-radius: var(--control-radius-round-m);
  overflow-y: scroll;
  scrollbar-width: none;

  .table {
    border: 0;
    //border-collapse: initial;
    border-spacing: 0;

    .header {
      position: sticky;
      top: 48px;
    }
  }
}

.laptop {
  .table {
    .header {
      top: 40px;
    }
  }
}
