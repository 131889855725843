$VendistaSignInFormWidth: 512px;
$logoShakerWidth: 76px;
$logoShakerHeight: 48px;
$swapIconSize: 28px;
$logoVendistaWidth: 71px;
$logoVendistaHeight: 48px;

.VendistaSignInForm {
  width: $VendistaSignInFormWidth;
  padding: 0 0 var(--modal-padding-bottom) 0;

  .modalTitle {
    text-align: center;
  }

  .logoShaker {
    width: $logoShakerWidth;
    height: $logoShakerHeight;

    color: var(--typo-main-brand);
  }

  .swapIcon {
    width: $swapIconSize;
    height: $swapIconSize;

    color: var(--typo-main-primary);
  }

  .logoVendista {
    width: $logoVendistaWidth;
    height: $logoVendistaHeight;
  }
}
