$CreateTelegramNotificationSettingsWidth: 512px;
$logoShakerWidth: 76px;
$logoShakerHeight: 48px;
$arrowNextIconWidth: 39px;
$arrowNextIconHeight: 48px;
$logoTelegramSize: 48px;

.CreateTelegramNotificationSetting {
  width: $CreateTelegramNotificationSettingsWidth;
  padding: 0 0 var(--modal-padding-bottom) 0;

  .modalTitle {
    text-align: center;
  }

  .logoShaker {
    width: $logoShakerWidth;
    height: $logoShakerHeight;

    color: var(--typo-main-brand);
  }

  .arrowNextIcon {
    width: $arrowNextIconWidth;
    height: $arrowNextIconHeight;

    color: var(--typo-status-system);
  }

  .logoTelegram {
    width: $logoTelegramSize;
    height: $logoTelegramSize;
  }
}
