$MachineSettingsContentCard: 80px;
$MachineSettingsVersionNumberWidth: 160px;
$MachineSettingsStatusWidth: 378px;
$MachineSettingsInfoWidth: 550px;
$imgWidth: 28px;
$imgHeight: 48px;

.MachineSettingsActions {
  height: $MachineSettingsContentCard;

  .MachineSettingsInfo {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    display: flex;

    border-radius: var(--control-radius-round-m);
    box-sizing: border-box;
    width: $MachineSettingsInfoWidth;
    padding: var(--space-m) var(--space-xl);

    .machineImgWrapper {
      img {
        width: $imgWidth;
        height: $imgHeight;
      }
    }
  }

  .MachineSettingsStatus {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    display: flex;

    border-radius: var(--control-radius-round-m);
    box-sizing: border-box;
    width: $MachineSettingsStatusWidth;
    padding: var(--space-m) var(--space-xl);
  }

  .MachineSettingsVersionNumber {
    align-items: flex-start;
    justify-content: flex-start;

    display: flex;

    border-radius: var(--control-radius-round-m);
    box-sizing: border-box;
    width: $MachineSettingsVersionNumberWidth;
    padding: var(--space-m) var(--space-xl);
  }
}
