$historyCardHeight: 66px;
$avatarWidth: 40px;
$avatarHeight: 40px;
$avatarFontSize: 14px;
$MachineHomePageHistoryMinHeight: 200px;

.MachineHomePageHistory {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: var(--space-m);
  min-height: $MachineHomePageHistoryMinHeight;

  display: flex;

  overflow-y: auto;

  padding: calc(var(--space-m) - 1px) var(--space-xl) var(--space-xl) var(--space-xl);

  scrollbar-width: none;
  -ms-overflow-style: none;

  .historyCard {
    justify-content: space-between;

    border: 1px solid var(--bg-border-tertriary);
    border-radius: var(--control-radius-round-m);
    box-sizing: border-box;
    max-height: $historyCardHeight;
    padding: var(--space-s) var(--space-m);

    background: var(--bg-main-tertriary);

    .avatar {
      align-items: center;
      justify-content: center;

      display: flex;

      width: $avatarWidth;
      height: $avatarHeight;

      font-size: $avatarFontSize;

    }
  }
}

.MachineHomePageHistory::-webkit-scrollbar {
  display: none;
}
