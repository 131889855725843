.formItem {
  display: flex;
  flex-direction: column;
  gap: var(--space-2xl);

  width: calc(100% - 2 * var(--space-l));

  padding: var(--space-l);

  border-radius: var(--control-radius-round-m);
  background-color: var(--bg-main-tertriary);
}