$iconSize: 20px;

.lineThrough {
  text-decoration: line-through
}

.machineSales {
  margin-bottom: var(--space-3xl);
  padding-top: var(--space-2xl);
}

.dateSaleCell {
  width: 17%;
}

.iconSize {
  width: $iconSize;
  height: $iconSize;
}

.terminalTransactionIdCell {
  width: 17%;
}

.customTablePadding {
  padding: 0 var(--space-m) var(--space-m) var(--space-m);
}
