.AuthPage {
  width: 100%;
  height: 100%;

  padding-top: 100px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: var(--space-5xl);

  background-size: cover;
  background-image: url('./auth_background.png');

  .card {
    display: flex;
    width: 432px;
    min-height: 200px;
    //max-height: 700px;
    padding: var(--space-5xl) var(--modal-padding-right) var(--space-5xl) var(--modal-padding-left);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: var(--control-radius-round-l);
    background: var(--bg-main-secondary);

    .logo {
      width: 158px;
      height: auto;
      color: var(--typo-main-brand);

      margin-bottom: var(--space-2xl);
    }
  }
}
