$rowItem-width: 700px;
$unitsSelect-width: 125px;

.formItem {
  display: flex;
  gap: var(--space-s);
  width: $rowItem-width;

  .componentsSelect {
    flex: 1;
  }

  .unitsSelect {
    width: $unitsSelect-width;
  }
}