.contentCard {
  display: flex;
  background: var(--bg-main-tertriary);
}

.fullWidthCard {
  @extend .contentCard;
  align-items: flex-start;
  width: 100%;
}

.halfWidthCard {
  @extend .contentCard;
  align-items: center;
  width: 50%;
}

.organizationBasicInfo {
  width: 100%;
}

.centeredText {
  text-align: center;
}

