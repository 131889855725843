

.itemCard {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);

  width: calc(100% - 2 * var(--space-l));

  padding: var(--space-l);

  border-radius: var(--control-radius);
  background-color: var(--bg-main-tertriary);

  // TODO: тут заменить тень и цвет на константы
  box-shadow: 0 2px 20px 0 rgba(28, 28, 28, 0.05);

  .tagsList {
    display: flex;
    flex-direction: column;
    gap: var(--space-m);
  }
}
