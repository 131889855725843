$MachineSnackRefillContent-width: 540px;
$buttonContainer-top: 100px;

.MachineSnackRefill {
  flex-direction: column;

  display: flex;

  margin-top: var(--space-2xl);
  padding-bottom: var(--space-l);

  .MachineSnackRefillContent {
    flex-grow: 1;

    .leftSide {
      min-width: $MachineSnackRefillContent-width;
      width: calc(50% - var(--space-3xl) / 2);
    }

    .rightSide {
      width: calc(50% - var(--space-3xl) / 2);
    }
  }

  .buttonContainer {
    position: absolute;
    bottom: $buttonContainer-top;
    right: 0;
    padding: var(--space-s);

    border-radius: var(--control-radius-round-m) 0 0 var(--control-radius-round-m);

    background: var(--bg-main-secondary);
  }
}