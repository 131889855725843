$discountFilterModal-width: 344px;
$codeFilterModal-width: 376px;

.PromoCodeList {

}

.filterModal {
  padding: var(--space-2xl);

  border-radius: var(--control-radius-round-l);
  border: 1px solid var(--bg-split-ghost);
  background-color: var(--bg-main-tertriary);
}

.favoriteContainer {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-radius: 100%;
    background: var(--control-ghost-bg-bg-hover);
  }

  .favorite {
    color: #E6B638;
  }

  .noFavorite {
    color: var(--typo-main-secondary);
  }
}

.nameCell {
  cursor: pointer;
}

.amountCard {
  padding: 8px;

  border-radius: 4px;
  background: var(--control-ghost-bg-bg-select);
}

.discountFilterModal {
  width: $discountFilterModal-width;
}

.codeFilterModal {
  width: $codeFilterModal-width;
}

.codeColumn {
  width: 26%;
}

.discountColumn {
  width: 11%;
}

.groupNameColumn {
  width: 22%;
}

.descriptionColumn {
  width: 33%;
}
