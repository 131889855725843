.MachineSnackFillingTableWrapper {
  display: block;

  padding: var(--space-xs) var(--space-m) var(--space-m) var(--space-m);

  .content {
    display: block;

    margin: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 0;

    .ContentCard {
      align-content: flex-start;
      align-items: flex-start;
      row-gap: var(--space-xs);

      margin-bottom: var(--space-s);
      border-radius: var(--control-radius-round-m);
      box-sizing: border-box;
      width: 100%;
      max-width: 100%;
      padding: var(--space-s);

      background: var(--bg-main-tertriary);
    }

    .ContentCardHeader {
      align-items: flex-start;

      display: flex;

      padding: 0 var(--space-2xs);
    }
  }

  .ContentCard:last-child {
    margin-bottom: 0;
  }
}
