.MachinePrices {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  justify-content: space-between;
  padding-top: var(--space-l);

  .actions {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: var(--space-l);
  }
}
