$MobileLeftMenuWidth: 232px;
$shakerLogoWidth: 85px;
$shakerLogoHeight: 12px;

.MobileLeftMenu {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  box-sizing: border-box;
  width: $MobileLeftMenuWidth;
  padding: 0 var(--space-m) var(--space-m);

  background: var(--bg-main-body);

  .menu {
    position: sticky;
    z-index: 2;
    top: 0;

    margin-bottom: var(--space-l);
    padding-top: var(--space-2xs);

    background: var(--bg-main-body);

    .shakerLogo {
      width: $shakerLogoWidth;
      height: $shakerLogoHeight;

      color: var(--bg-main-brand);
    }
  }
}