.MachineFillingInfo {
  display: flex;
  flex-direction: row;
  gap: var(--space-5xl);
  width: 100%;

  .leftSide {
    width: calc(50% - var(--space-5xl) / 2);
  }

  .rightSide {
    width: calc(50% - var(--space-5xl) / 2);
  }
}

.laptop {
  gap: var(--space-3xl);

  &.MachineFillingInfo {
    .leftSide {
      width: calc(50% - var(--space-3xl) / 2);
    }

    .rightSide {
      width: calc(50% - var(--space-3xl) / 2);
    }
  }
}

.actions {
  position: absolute;
  bottom: 20px;
  right: 20px;
}


