.MachineFilling {
  display: flex;

  margin-top: 0;

  &.MachineFilling {
    margin-top: var(--space-3xl);
  }

  &.isLaptop {

    &.MachineFilling {
      margin-top: var(--space-2xl);
    }
  }
}
