.BaseTableHeaderColumn {
  align-items: flex-end;

  display: flex;
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}

.right {
  justify-content: flex-end;
}
