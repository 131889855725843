$contentCardHeight: 100%;
$contentCardWidth: 100%;

$contentCardHeight-laptop: 100%;
$contentCardWidth-laptop: 100%;

.contentCard {
  box-sizing: border-box;

  width: $contentCardWidth;
  height: $contentCardHeight;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: var(--space-l) var(--space-2xl) var(--space-2xl) var(--space-2xl);
  gap: var(--space-l);
  align-self: stretch;

  background-color: var(--bg-main-secondary);
  border-radius: var(--control-radius-round-l);
  border: 1px solid var(--bg-border-tertriary);
}

.laptop {
  width: $contentCardHeight-laptop;
  height: $contentCardWidth-laptop;

  padding: var(--space-s) var(--space-l);
  gap: var(--space-l);
}
