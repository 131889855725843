$VendistaLogoWidth: 40px;
$VendistaLogoHeight: 43px;

.VendistaSettings {
  .VendistaNotification {
    border-radius: var(--control-radius-round-m);
    padding: calc(var(--space-m) - 1px) var(--space-xl) var(--space-m) calc(var(--space-xl) - 1px);

    background: var(--bg-main-secondary);

    .VendistaLogo {
      width: $VendistaLogoWidth;
      height: $VendistaLogoHeight;
    }
  }
}
