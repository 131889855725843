$AddScheduleForm-width: 522px;

.AddScheduleForm {
  width: $AddScheduleForm-width;

  .timeField {
    flex: 1;
  }

  .weekDayItem {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;

    border-radius: 99px;
    border: 1px solid var(--control-ghost-border-border);
    background: var(--control-ghost-bg-bg);
    color: var(--control-ghost-typo-typo);
    cursor: pointer;

    &:hover {
      background: var(--control-ghost-bg-bg-hover);
      color: var(--control-ghost-typo-typo-hover);
    }
  }

  .selected {
    border: 1px solid rgba(0, 0, 0, 0);
    background: var(--control-secondary-bg-bg);
    color: var(--control-secondary-typo-typo);

    &:hover {
      background: var(--control-secondary-bg-bg-hover);
      color: var(--control-secondary-typo-typo-hover);
    }
  }
}

.card {
  padding: var(--space-m) var(--space-l);
  gap: var(--space-m);

  border-radius: var(--control-radius-round-m);
  background: var(--bg-main-tertriary);
}
