$bgColor: var(--bg-table-primary);

.recipeContextmenu {
  background-color: $bgColor;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: var(--space-3xl);
  margin-bottom: var(--space-l);
}
