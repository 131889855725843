$lineWidth: 12px;
$lineBorderRadius: 8px;

$storageSchemeCardWidth: 16px;
$editStorageSchemeCardWidth: 20px;

$textHeight: 16px;

$cellNumberLineHeight: 22px;
$rightTextFieldWidth: 53px;
$cupTextFieldWidth: 44px;

$cellIconContainerHeight: 24px;

.storageSchemeCard {
  flex-direction: column;
  align-items: center;
  gap: var(--space-3xs);

  display: flex;

  width: $storageSchemeCardWidth;

  cursor: pointer;

  --bg-scheme-color: var(--bg-main-tertriary);
  --bg-scheme-color-hover: var(--bg-main-quaternary);
  --bg-scheme-color-active: var(--bg-main-quaternary);

  &.isRefill {
    gap: var(--space-2xs);

    width: $editStorageSchemeCardWidth;
  }

  .text {
    align-items: center;
    white-space: nowrap;

    display: flex;

    font-size: $textHeight;
    font-weight: 500;
    line-height: $textHeight;

    color: var(--typo-color);
  }

  .cellIconContainer {
    align-items: center;

    display: flex;
    height: $cellIconContainerHeight;

    .cellIcon {
      height: $cellIconContainerHeight;
    }

    color: var(--control-disable-bg-bg);
  }

  .cellNumberText {
    align-items: center;

    display: flex;

    color: var(--typo-color);
  }

  .icon {
    gap: var(--space-xs);

    display: flex;

    fill: var(--typo-color);
    color: var(--typo-color);
  }

  &.horizontal {
    gap: var(--space-xs);
    flex-direction: row-reverse;

    width: 100%;
  }
}

.rightTextField {
  width: $rightTextFieldWidth;
}

.cupTextField {
  width: $cupTextFieldWidth;
}

.text {
  &.PRIMARY {
    color: var(--storage-card-typo-primary-default);
  }

  &.SECONDARY {
    color: var(--storage-card-typo-secondary-default);
  }

  &.COFFEE {
    color: var(--storage-card-typo-coffee-default);
  }

  &.DEFAULT {
    color: var(--storage-card-typo-default-default);
  }
}
