$height: 8px;
$width: 8px;
$border-radius: 2px;

.matrixItem {
  width: $width;
  height: $height;
  border-radius: $border-radius;
  background-color: var(--schema-success-default);
}
