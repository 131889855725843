.ScheduleCardByForm {
  display: flex;
  padding: var(--space-l);
  align-items: center;
  gap: var(--space-5xl);

  border-radius: var(--control-radius-round-s);
  background: var(--bg-main-tertriary);
  cursor: pointer;

  //&:hover {
  //  background-color: var(--control-secondary-bg-bg-hover);
  //}
}