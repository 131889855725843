.MemberKeyHistory {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: var(--space-m);

  overflow-y: auto;

  .historyItemCard {
    padding: var(--space-l);

    border-radius: var(--control-radius-round-m);
    background: var(--bg-main-tertriary);

    border: 1px solid var(--bg-border-tertriary);

    .text {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.laptop {
  gap: var(--space-s);

  .historyItemCard {
    padding: var(--space-m);
  }
}
