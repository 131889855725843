$MachineConnectHeight: 80px;
$editModalWidth: 620px;

.MachineConnect {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  display: flex;

  border-radius: var(--control-radius-round-m);
  height: $MachineConnectHeight;
  padding: var(--space-m) var(--space-xl);

  color: var(--typo-main-primary)
}

.editModal {
  width: $editModalWidth;
}

.connectButton {
  font-weight: 500;
  color: var(--typo-main-brand);

  &:hover {
    color: var(--typo-main-brand) !important;
  }
}
