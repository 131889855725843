$avatarSize: 48px;

.MemberKeyProfile {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--space-l);

  flex: 0;

  .profileCard {
    padding: var(--space-m) var(--space-l);

    border-radius: var(--control-radius-round-m);
    background: var(--bg-main-tertriary);

    border: 1px solid var(--bg-border-tertriary);

    .text {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .avatar {
      height: $avatarSize;
      width: $avatarSize;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
    }
  }
}

.laptop {
  gap: var(--space-m);

  .profileCard {
    padding: var(--space-s) var(--space-l);
  }
}
