$documentCard-minWidth: 400px;
$documentCard-maxWidth: 500px;
$documentCard-height: 150px;

.documentsList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-l);

  .addButton {
    min-width: $documentCard-minWidth;
    max-width: $documentCard-maxWidth;
    height: $documentCard-height;
  }

  .documentCardWrapper {
    min-width: $documentCard-minWidth;
    max-width: $documentCard-maxWidth;
    height: $documentCard-height;

    .documentCard {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .action {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
