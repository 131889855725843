$storageHeight: 850px;
$rightSideMinWidth: 600px;

.storage {
  overflow-y: scroll;

  margin-right: var(--space-5xl);
  height: $storageHeight;
  width: 100%;
}

.scheme {
  flex-direction: column;
  gap: var(--space-s);

  display: flex;

  width: 100%;

  .product {
    gap: var(--space-l);

    display: flex;

    width: 100%;

    &.isRefill {
      flex-direction: column;
    }
  }
}

.rightSide {
  flex-direction: column;
  justify-content: space-between;

  display: flex;

  min-width: $rightSideMinWidth;

  .actions {
    justify-content: flex-end;
    gap: var(--space-l);

    display: flex;

    margin-top: var(--space-l);
  }
}

.viewPrimary {
  --bg-color: var(--storage-card-bg-primary-default);
  --bg-color-hover: var(--storage-card-bg-primary-hover);
  --bg-color-active: var(--storage-card-bg-primary-active);

  --bg-scheme-color: var(--bg-main-tertriary);
  --bg-scheme-color-hover: var(--storage-card-bg-primary-hover);
  --bg-scheme-color-active: var(--storage-card-bg-primary-active);

  --typo-color: var(--storage-card-typo-primary-default);
  --typo-color-hover: var(--storage-card-typo-primary-hover);
  --typo-color-active: var(--storage-card-typo-primary-default);

  --lineSlider-color: var(--schema-primary-default);
  --lineSlider-color-hover: var(--schema-primary-hover);
  --lineSlider-color-active: var(--schema-primary-active);
}

.viewSecondary {
  --bg-color: var(--storage-card-bg-secondary-default);
  --bg-color-hover: var(--storage-card-bg-secondary-hover);
  --bg-color-active: var(--storage-card-bg-secondary-active);

  --bg-scheme-color: var(--bg-main-tertriary);
  --bg-scheme-color-hover: var(--storage-card-bg-secondary-hover);
  --bg-scheme-color-active: var(--storage-card-bg-secondary-active);

  --typo-color: var(--storage-card-typo-secondary-default);
  --typo-color-hover: var(--storage-card-typo-secondary-hover);
  --typo-color-active: var(--storage-card-typo-secondary-active);

  --lineSlider-color: var(--schema-secondary-default);
  --lineSlider-color-hover: var(--schema-secondary-hover);
  --lineSlider-color-active: var(--schema-secondary-active);
}

.viewDefault {
  --bg-color: var(--storage-card-bg-default-default);
  --bg-color-hover: var(--storage-card-bg-default-hover);
  --bg-color-active: var(--storage-card-bg-default-active);

  --bg-scheme-color: var(--bg-main-tertriary);
  --bg-scheme-color-hover: var(--storage-card-bg-default-hover);
  --bg-scheme-color-active: var(--storage-card-bg-default-active);

  --typo-color: var(--storage-card-typo-default-default);
  --typo-color-hover: var(--storage-card-typo-default-hover);
  --typo-color-active: var(--storage-card-typo-default-active);

  --lineSlider-color: var(--schema-default-default);
  --lineSlider-color-hover: var(--schema-default-hover);
  --lineSlider-color-active: var(--schema-default-active);
}

.viewCoffee {
  --bg-color: var(--storage-card-bg-coffee-default);
  --bg-color-hover: var(--storage-card-bg-coffee-hover);
  --bg-color-active: var(--storage-card-bg-coffee-active);

  --bg-scheme-color: var(--bg-main-tertriary);
  --bg-scheme-color-hover: var(--storage-card-bg-coffee-hover);
  --bg-scheme-color-active: var(--storage-card-bg-coffee-active);

  --typo-color: var(--storage-card-typo-coffee-default);
  --typo-color-hover: var(--storage-card-typo-coffee-hover);
  --typo-color-active: var(--storage-card-typo-coffee-active);

  --lineSlider-color: var(--schema-coffee-default);
  --lineSlider-color-hover: var(--schema-coffee-hover);
  --lineSlider-color-active: var(--schema-coffee-active);
}

.viewWater {
  --bg-color: var(--storage-card-bg-water-default);
  --bg-color-hover: var(--storage-card-bg-water-hover);
  --bg-color-active: var(--storage-card-bg-water-active);

  --bg-scheme-color: var(--bg-main-tertriary);
  --bg-scheme-color-hover: var(--storage-card-bg-water-hover);
  --bg-scheme-color-active: var(--storage-card-bg-water-active);

  --typo-color: var(--storage-card-typo-water-default);
  --typo-color-hover: var(--storage-card-typo-water-hover);
  --typo-color-active: var(--storage-card-typo-water-active);

  --lineSlider-color: var(--schema-water-default);
  --lineSlider-color-hover: var(--schema-water-hover);
  --lineSlider-color-active: var(--schema-water-active);
}

.viewDisposable {
  --bg-color: var(--storage-card-bg-disposable-default);
  --bg-color-hover: var(--storage-card-bg-disposable-hover);
  --bg-color-active: var(--storage-card-bg-disposable-active);

  --bg-scheme-color: var(--bg-main-tertriary);
  --bg-scheme-color-hover: var(--storage-card-bg-disposable-hover);
  --bg-scheme-color-active: var(--storage-card-bg-disposable-active);

  --typo-color: var(--storage-card-typo-disposable-default);
  --typo-color-hover: var(--storage-card-typo-disposable-hover);
  --typo-color-active: var(--storage-card-typo-disposable-active);

  --lineSlider-color: var(--schema-disposable-default);
  --lineSlider-color-hover: var(--schema-disposable-hover);
  --lineSlider-color-active: var(--schema-disposable-active);
}
