$bgColor: var(--bg-table-secondary);
$bgColor-zebra: var(--bg-table-tetriary);
$productListItem-height: 48px;

.productListItem {
  display: flex;
  padding: 0 var(--space-m);
  align-items: center;
  gap: var(--space-s);
  align-self: stretch;
  border-radius: var(--control-radius-l);
  background-color: $bgColor;
  height: $productListItem-height;
}

.productLineContextMenu {
  background-color: $bgColor;
}

.zebra {
  background-color: $bgColor-zebra;
}
