$header-padding: 21px;
$unitTextMinWidth: 30px;
$cellHeight: 31.3px;
$firstCellHeight: 46px;
$cellNumberWidth: 22px;
$primaryTextFieldWidth: 54px;
$unitTextPadding: 6px;

.MachineFillingInfoTable {
  width: 100%;
  height: auto;
  padding-bottom: var(--space-m);
  border-radius: var(--control-radius-round-m);

  .table {
    border: 0;
    border-spacing: 0;
    width: 100%;

    .row {
      gap: var(--space-s);
      align-items: center;

      display: flex;

      box-sizing: border-box;
      border-bottom: 1px solid var(--bg-split-ghost);
      width: 100%;
      padding-left: var(--space-2xs);

      .cell {
        box-sizing: border-box;
        padding: 0;
      }

      .cellNumberCell {
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        display: flex;

        box-sizing: border-box;
        width: $cellNumberWidth;

        text-align: start;

        .cellIcon {
          color: var(--control-disable-bg-bg);
        }
      }

      .brandCell {
        width: 20.7%;
      }

      .ingredientLineCell {
        width: 20.8%;
      }

      .ingredientCell {
        width: 21.9%;
      }

      .volumeCell {
        flex: auto;
        justify-content: space-between;

        display: flex;

        width: 82px;

        text-align: end;

        .primaryTextField{
          box-sizing: border-box;
          width: $primaryTextFieldWidth
        }
      }

      .actionCell {
        width: 6%;
      }
    }

    .head {
      .row {
        flex-direction: row;
        gap: var(--space-s);

        display: flex;

        box-sizing: border-box;
        padding: var(--space-xs) var(--space-m) 0 var(--space-2xs);

        .cellHeader {
          text-align: start;
        }

        .cellNumberCell {
          box-sizing: border-box;;
          width: var(--space-2xl);
          padding-left: 0;

          text-align: center;
        }

        .brandCell {
          width: 22.5%;
          padding-left: var(--space-2xs);
        }

        .ingredientLineCell {
          width: 22%;
        }

        .ingredientCell {
          flex: 1
        }

        &:first-child {
          border-bottom: none;
        }
      }
    }

    .body {

      .row {
        &:first-child {
          .cell {
            height: $firstCellHeight;
            padding-top: $header-padding;
            padding-bottom: var(--space-2xs);
          }
        }

        &:last-child {
          .cell {
            border: 0;
          }
        }

        &.hover {
          &.PRIMARY {
            background-color: var(--storage-card-bg-primary-hover);

            .text {
              color: var(--storage-card-typo-primary-hover);
            }

            .volumeText {
              color: var(--typo-main-secondary)
            }
          }

          &.SECONDARY {
            background-color: var(--storage-card-bg-secondary-hover);

            .text {
              color: var(--storage-card-typo-secondary-hover);
            }

            .volumeText {
              color: var(--typo-main-secondary)
            }
          }

          &.COFFEE {
            background-color: var(--storage-card-bg-coffee-hover);

            .text {
              color: var(--storage-card-typo-coffee-hover);
            }

            .volumeText {
              color: var(--typo-main-secondary)
            }
          }

          &.DEFAULT {
            background-color: var(--storage-card-bg-default-hover);

            .text {
              color: var(--storage-card-typo-default-hover);
            }

            .volumeText {
              color: var(--typo-main-secondary)
            }
          }
        }

        &.PRIMARY {
          .text {
            color: var(--storage-card-typo-primary-default);

            &.isActive {
              color: var(--control-disable-typo-typo);
            }
          }

          .volumeText {
            color: var(--typo-main-secondary)
          }
        }

        &.SECONDARY {
          .text {
            color: var(--storage-card-typo-secondary-default);

            &.isActive {
              color: var(--control-disable-typo-typo);
            }
          }

          .volumeText {
            color: var(--typo-main-secondary)
          }
        }

        &.COFFEE {
          .text {
            color: var(--storage-card-typo-coffee-default);

            &.isActive {
              color: var(--control-disable-typo-typo);
            }
          }

          .volumeText {
            color: var(--typo-main-secondary)
          }
        }

        &.DEFAULT {
          .text {
            color: var(--storage-card-typo-default-default);

            &.isActive {
              color: var(--control-disable-typo-typo);
            }
          }

          .volumeText {
            color: var(--typo-main-secondary)
          }
        }

        &:last-child {
          border-bottom: none;
        }

        .cell {
          overflow: hidden;
          text-overflow: ellipsis;

          height: $cellHeight;
          box-sizing: border-box;
          padding: var(--space-2xs) 0;

          &:last-child {
            padding-right: 0;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

.text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.unitText {
  width: $unitTextMinWidth;
  text-align: center;
  align-content: center;
  height: var(--height-xs);
  box-sizing: border-box;
  padding: 0 $unitTextPadding;
}
