$sliderWidth: 300px;
$sliderPaddingLeft: 6px;
$comboBoxWidth: 29.7%;
$textFieldWidth: 42%;
$datePickerWidth: 376px;

.OrganizationSaleFilters {

  .contentCard {
    justify-content: flex-start;
    flex-direction: row;
    column-gap: var(--space-s);

    display: flex;

    border-radius: var(--control-radius-round-m);
    padding: var(--space-s) var(--space-m) var(--space-m) var(--space-m);

    background: var(--bg-main-secondary);

    .comboBox {
      width: $comboBoxWidth
    }
  }

  .verticalContentCard {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    row-gap: var(--space-l);

    display: flex;

    border-radius: var(--control-radius-round-s);
    padding: calc(var(--space-m) + 2px)var(--space-m) var(--space-m) var(--space-m);

    background: var(--bg-main-secondary);

    .textField {
      width: $textFieldWidth;
    }

    .slider {
      width: $sliderWidth;
      padding-left: $sliderPaddingLeft;
    }

    .datePicker {
      width: $datePickerWidth;
    }
  }
}