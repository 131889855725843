.group {
  border-radius: var(--control-radius-l);
  transition: background 0.3s 0.3s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.5, 1);

  .list {
    margin-left: calc(var(--space-l) - 1px);

    max-height: 0;

    transition: max-height 0.3s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.5, 1);

    overflow: hidden;

    &.isOpen {
      max-height: var(--collapse-body-max-height);
    }
  }

  &.select {
    background: var(--bg-main-secondary);
  }

  &:hover {
    background: var(--bg-main-secondary);
  }
}

.LeftMenuItemGroupCard {
  align-items: center;
  gap: var(--space-xs);
  justify-content: space-between;
  flex: 1;

  display: flex;

  border-radius: var(--control-radius-m);
  //width: 232px;
  min-height: var(--control-height-m);
  padding: 0 var(--space-s) 0 calc(var(--space-s) - 1px);

  background: var(--bg-main-body);
  cursor: pointer;

  color: var(--typo-main-secondary);

  &:hover {
    background: var(--control-clear-bg-bg-hover);

    color: var(--typo-main-secondary);

    .text {
      color: var(--typo-main-secondary);
    }
  }

  &.select {
    background: var(--bg-main-secondary);

    color: var(--control-clear-typo-typo-select);

    &:hover {
      background: var(--control-clear-bg-bg-hover);
    }

    .text {
      color: var(--control-clear-typo-typo-select);
    }

    &.single {
      background: var(--control-clear-bg-bg-select);

      &:hover {
        background: var(--control-clear-bg-bg-select);
      }
    }
  }
}
