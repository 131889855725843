/* stylelint-disable */
.Theme_color_gpnDefault {
  --color-scroll-bg: var(--bg-main-quaternary);
  --color-scroll-thumb:  var(--control-default-typo-typo-placeholder);
  --color-scroll-thumb-hover:  var(--control-default-typo-typo-placeholder);

  /*// Цвета тени /////////// *!*/
  --color-shadow-group-1: rgba(18, 18, 18, 0.3);
  --color-shadow-group-2: rgba(18, 18, 18, 0.3);
  --color-shadow-layer-1: rgba(18, 18, 18, 0.3);
  --color-shadow-layer-2: rgba(18, 18, 18, 0.3);
  --color-shadow-modal-1: rgba(18, 18, 18, 0.3);
  --color-shadow-modal-2: rgba(18, 18, 18, 0.3);

  --color-shadow-field-default-focus: #06F;
  --color-shadow-field-default-active: #06F;
  --color-shadow-field-success-focus: #09B37B;
  --color-shadow-field-success-active: #09B37B;
  --color-shadow-field-warning-focus: #EB6D00;
  --color-shadow-field-warning-active: #EB6D00;
  --color-shadow-field-alert-focus: #EB3333;
  --color-shadow-field-alert-active: #EB3333;
  /* Light */
  /* colors */
  --bg-border-primary: #dadde0;
  --bg-border-secondary: #d9d9d9;
  --bg-border-tertriary: #ebebeb;
  --bg-link-link: #0078d2;
  --bg-main-body: #f2f5f5;
  --bg-main-brand: #66cc33;
  --bg-main-default: #ffffff;
  --bg-main-primary: #f0f0f0;
  --bg-main-quaternary: #e8eaeb;
  --bg-main-secondary: #ffffff;
  --bg-main-select: #dfe0e2;
  --bg-main-tertriary: #f4f6f7;
  --bg-shadow-shadow-1: #00000014;
  --bg-split-ghost: #1212121a;
  --bg-split-paranja: #00000066;
  --bg-split-soft: #ffffffe5;
  --bg-split-stripe: #1010100d;
  --bg-split-tone: #000000d9;
  --bg-status-alert: #f6c0c0;
  --bg-status-caution: #efe7b3;
  --bg-status-critical: #f2a6bf;
  --bg-status-normal: #c3e5b2;
  --bg-status-success: #b4e6d6;
  --bg-status-system: #d8dee2;
  --bg-status-warning: #f3d6ac;
  --bg-table-primary: #ffffff;
  --bg-table-secondary: #f5f5f5;
  --bg-table-tetriary: #ebebeb;
  --card-bg-default: #f2f4f5;
  --card-bg-hover: #e3e4e5;
  --control-clear-bg-bg: #ffffff00;
  --control-clear-bg-bg-alert-hover: #fcf1f1;
  --control-clear-bg-bg-hover: #1c1c1c1a;
  --control-clear-bg-bg-select: #bffaa2;
  --control-clear-bg-bg-success-hover: #e3f0dd;
  --control-clear-typo-typo: #343434;
  --control-clear-typo-typo-alert: #eb3333;
  --control-clear-typo-typo-alert-hover: #eb3333;
  --control-clear-typo-typo-alert-succcess: #66cc33;
  --control-clear-typo-typo-hover: #1c1c1c;
  --control-clear-typo-typo-select: #66cc33;
  --control-clear-typo-typo-succeess: #66cc33;
  --control-default-bg-bg: #f2f3f5;
  --control-default-border-bg-border-active: #44cc29;
  --control-default-border-bg-border-success: #34e8ac;
  --control-default-border-bg-border-success-hover: #27e292;
  --control-default-border-bg-border-warning: #f2c94c;
  --control-default-border-bg-border-warning-hover: #ffc000;
  --control-default-border-border: #d5d5d7;
  --control-default-border-border-alert: #ff4443;
  --control-default-border-border-alert-hover: #f01616;
  --control-default-border-border-hover: #b8b9bb;
  --control-default-typo-typo: #121212;
  --control-default-typo-typo-2: #52cc14;
  --control-default-typo-typo-placeholder: #1c1c1c66;
  --control-disable-bg-bg: #e3e6e6;
  --control-disable-border-border: #fafafa00;
  --control-disable-typo-typo: #b3b3b3;
  --control-ghost-bg-bg: #e8ebeb00;
  --control-ghost-bg-bg-hover: #e6e8e9;
  --control-ghost-bg-bg-select: #cfd0d3;
  --control-ghost-border-border: #cfd0d3;
  --control-ghost-border-border-hover: #cfd0d3;
  --control-ghost-border-border-select: #cfd0d3;
  --control-ghost-typo-typo: #4c4c4c;
  --control-ghost-typo-typo-hover: #4c4c4c;
  --control-ghost-typo-typo-select: #4c4c4c;
  --control-primary-bg-bg: #66cc33;
  --control-primary-bg-bg-alert: #e55050;
  --control-primary-bg-bg-alert-hover: #e54545;
  --control-primary-bg-bg-hover: #45ce00;
  --control-primary-typo-typo: #ffffff;
  --control-primary-typo-typo-hover: #ffffff;
  --control-secondary-bg-bg: #e1e1e1;
  --control-secondary-bg-bg-hover: #e2e2e2;
  --control-secondary-typo-typo: #48b212;
  --control-secondary-typo-typo-hover: #48b212;
  --point-card-bg-alert-active: #f4d4d4;
  --point-card-bg-default: #fafafa;
  --point-card-bg-default-active: #e5e5e5;
  --point-card-bg-success-active: #bfffed;
  --point-card-bg-warning-active: #f9ddb7;
  --point-card-border-alert: #d98181;
  --point-card-border-default: #d1d1d1;
  --point-card-border-success: #66ccb0;
  --point-card-border-warning: #f2af55;
  --schema-alert-active: #b25959;
  --schema-alert-default: #e57373;
  --schema-alert-hover: #cc6666;
  --schema-caution-active: #a68d43;
  --schema-caution-default: #d9b957;
  --schema-caution-hover: #bfa34d;
  --schema-coffee-active: #8c6043;
  --schema-coffee-default: #bf835b;
  --schema-coffee-hover: #a6724f;
  --schema-default-active: #999999;
  --schema-default-default: #bfbfbf;
  --schema-default-hover: #b3b3b3;
  --schema-disposable-active: #808080;
  --schema-disposable-default: #bfbfbf;
  --schema-disposable-hover: #999999;
  --schema-primary-active: #188d99;
  --schema-primary-default: #22c8d9;
  --schema-primary-hover: #1ca4b2;
  --schema-secondary-active: #e04e5c;
  --schema-secondary-default: #db7a83;
  --schema-secondary-hover: #e3636f;
  --schema-success-active: #478c75;
  --schema-success-default: #60bf9f;
  --schema-success-hover: #53a68a;
  --schema-water-active: #358fb2;
  --schema-water-default: #45b9e5;
  --schema-water-hover: #3da4cc;
  --scrollbar-bg-active: #c1c1c1;
  --scrollbar-bg-default: #e6e6e6;
  --storage-card-bg-coffee-active: #bf8760;
  --storage-card-bg-coffee-default: #fafafa;
  --storage-card-bg-coffee-hover: #dab69d;
  --storage-card-bg-default-active: #e0e0e0;
  --storage-card-bg-default-default: #fafafa;
  --storage-card-bg-default-hover: #f0f0f0;
  --storage-card-bg-disposable-active: #e0e0e0;
  --storage-card-bg-disposable-default: #fafafa;
  --storage-card-bg-disposable-hover: #f0f0f0;
  --storage-card-bg-primary-active: #70c4cc;
  --storage-card-bg-primary-default: #fafafa;
  --storage-card-bg-primary-hover: #b9eef4;
  --storage-card-bg-secondary-active: #dba0a6;
  --storage-card-bg-secondary-default: #fafafa;
  --storage-card-bg-secondary-hover: #f4cace;
  --storage-card-bg-water-active: #5bc0e8;
  --storage-card-bg-water-default: #fafafa;
  --storage-card-bg-water-hover: #c4eeff;
  --storage-card-typo-coffee-active: #ffffff;
  --storage-card-typo-coffee-default: #6f4e37;
  --storage-card-typo-coffee-disable: #6f4e37;
  --storage-card-typo-coffee-hover: #6f4e37;
  --storage-card-typo-default-active: #7b7b7b;
  --storage-card-typo-default-default: #999999;
  --storage-card-typo-default-disable: #7b7b7b;
  --storage-card-typo-default-hover: #7b7b7b;
  --storage-card-typo-disposable-active: #999999;
  --storage-card-typo-disposable-default: #999999;
  --storage-card-typo-disposable-disable: #999999;
  --storage-card-typo-disposable-hover: #999999;
  --storage-card-typo-primary-active: #ffffff;
  --storage-card-typo-primary-default: #13b4c2;
  --storage-card-typo-primary-disable: #11a9b7;
  --storage-card-typo-primary-hover: #11a9b7;
  --storage-card-typo-secondary-active: #ffffff;
  --storage-card-typo-secondary-default: #db6e80;
  --storage-card-typo-secondary-disable: #d76a76;
  --storage-card-typo-secondary-hover: #d76a76;
  --storage-card-typo-water-active: #ffffff;
  --storage-card-typo-water-default: #23bdf0;
  --storage-card-typo-water-disable: #22bbe9;
  --storage-card-typo-water-hover: #22bbe9;
  --typo-link-link: #0078d2;
  --typo-link-link-hover: #008efa;
  --typo-link-link-minor: #1c5682;
  --typo-main-brand: #66cc33;
  --typo-main-ghost: #b8b8b8;
  --typo-main-primary: #21201f;
  --typo-main-secondary: #737373;
  --typo-status-alert: #ff4443;
  --typo-status-caution: #e5c419;
  --typo-status-critical: #c20a41;
  --typo-status-normal: #58c322;
  --typo-status-success: #24c38e;
  --typo-status-system: #7b8f9e;
  --typo-status-warning: #f38b01;
}
