$cellHeight: 48px;

.cell {
  height: $cellHeight;
  padding: var(--space-xs);

  background: var(--bg-main-tertriary);

  text-align: start;

  &:first-child {
    border-radius: var(--control-radius-round-m) 0 0 var(--control-radius-round-m);
    padding-left: calc(var(--space-m) + 1px)

  }

  &:last-child {
    border-radius: 0 var(--control-radius-round-m) var(--control-radius-round-m) 0;
    padding-right: var(--space-m);
  }

  &.fullWidth {
    width: 100%;
  }
}
