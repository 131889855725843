.card {
  padding: var(--space-5xl);
  width: calc(100% - 2 * var(--space-5xl));

  border-radius: var(--control-radius-round-l);
  background-color: var(--bg-main-secondary);
}

.tableWrapper {
  border-radius: var(--control-radius-round-m);
  overflow: hidden;
  border: 1px solid var(--bg-border-primary);
}

.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid var(--bg-border-primary);
  border-radius: var(--control-radius-round-m);
  overflow: hidden;

  .header {
    position: sticky;

    .columns {
      background-color: var(--bg-main-tertriary);
    }
  }

  .body {
    .row {
    }

    .zebra {
      background-color: var(--bg-table-secondary);
    }
  }

  .cell {
    height: 56px;
    padding: 0 var(--space-xs);
    text-align: start;
    border: 1px solid var(--bg-border-primary);

    width: 33%;

    &:first-child {
      padding-left: var(--space-2xl);
    }

    &:last-child {
      padding-right: var(--space-2xl);
    }

    &.alignLeft {
      text-align: start;
    }

    &.alignRight {
      text-align: end;
    }

    &.fullWidth {
      width: 100%;
    }
  }
}

.text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
