.rowContent {
  display: flex;
  padding: var(--space-l);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: calc(708px - 2 * var(--space-l));

  // TODO: убрать отсюда px после доработки темы
  border-radius: 20px;
  background-color: var(--bg-main-tertriary);

  .contentItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    gap: var(--space-xs);

    .firstBlock {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      align-self: stretch;
      gap: var(--space-xs);
    }
  }
}


