$iconSize: 512px;

.ErrorPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--space-l);

  .icon {
    width: $iconSize;
    height: $iconSize;
  }
}