.productLineList {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  width: 100%;
  margin-bottom: var(--space-3xl);

  .addButton {
    width: 100%;
    justify-content: center;
  }
}
