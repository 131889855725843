.cabinet {
  display: flex;
  flex-direction: column;
  gap: var(--space-3xl);
  height: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .headerAction {

    }

    .headerContent {
      display: flex;
      align-items:center ;
      gap: var(--space-2xl);

    }
  }

  .content {
    height: 100%;
  }
}
