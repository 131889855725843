$rowContent-width: 740px;

.rowContent {
  display: flex;
  padding: var(--space-l);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: $rowContent-width;

  // TODO: убрать отсюда px после доработки темы
  border-radius: 20px;
  background-color: var(--bg-main-tertriary);

  .contentItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-bottom: 1px solid var(--bg-border-primary);

    .descriptionAction {
      display: flex;
    }

    .description {
      overflow: hidden;
      max-height: 24px;
    }

    .openDescription {
      max-height: 1000px;
    }

    .openDescriptionText {
      cursor: pointer;
    }
  }
}
