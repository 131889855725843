.card {
  padding: var(--space-5xl);
  width: calc(100% - 2 * var(--space-5xl));

  border-radius: var(--control-radius-round-l);
  background-color: var(--bg-main-secondary);

  .secondaryCard {
    padding: var(--space-2xl);
    width: calc(100% - 2 *var(--space-2xl));

    border-radius: var(--control-radius-round-m);
    background-color: var(--bg-main-tertriary);

    .tertiaryCard {
      padding: var(--space-l);

      border-radius: var(--control-radius-round-m);
      background-color: var(--bg-main-quaternary);
    }
  }
}

.timeAddButton {
  width: 200px;
  height: 94px;

  display: flex;
  //padding: var(--space-l);
  justify-content: center;
  align-items: center;
  gap: var(--space-xs);
  align-self: stretch;
  color: var(--control-secondary-typo-typo);



  border-radius: var(--control-radius-round-s);
  background-color: var(--control-secondary-bg-bg);

  cursor: pointer;

  &:hover {
    background-color: var(--control-secondary-bg-bg-hover);
    color: var(--control-secondary-typo-typo-hover);
  }

  .text {
    color: var(--control-secondary-typo-typo);

    &:hover {
      color: var(--control-secondary-typo-typo-hover);
    }
  }
}
