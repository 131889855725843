.TableCard {
  display: flex;
  flex-direction: column;
  margin-top: var(--space-3xl);
  gap: var(--space-l);

  .TableContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: var(--space-l);
  }
}
