$buttonContainer-top: 100px;

.MachineSnackFilling {
  display: flex;

  margin-top: 0;

  &.MachineSnackFilling {
    margin-top: var(--space-2xl);
  }

  .buttonContainer {
    position: absolute;
    bottom: $buttonContainer-top;
    right: 0;
    align-items: center;

    border-radius: var(--control-radius-round-m) 0 0 var(--control-radius-round-m);
    padding: var(--space-s);

    background: var(--bg-main-secondary);
  }
}
