$grid-gap: 1px;
$gridContainer-height: 48px;

.gridContainer {
  display: grid;
  gap: $grid-gap;
  height: $gridContainer-height;
}

.gridRow {
  display: grid;
  gap: $grid-gap;
  width: 100%;
}
