/* stylelint-disable */
.Theme_color_gpnDark {
  --color-scroll-bg: var(--bg-main-quaternary);
  --color-scroll-thumb:  var(--control-default-typo-typo-placeholder);
  --color-scroll-thumb-hover:  var(--control-default-typo-typo-placeholder);

  /*// Цвета тени /////////// *!*/
  --color-shadow-group-1: #121212;
  --color-shadow-group-2: #121212;
  --color-shadow-layer-1: #121212;
  --color-shadow-layer-2: #121212;
  --color-shadow-modal-1: #121212;
  --color-shadow-modal-2: #121212;

  --color-shadow-field-default-focus: rgba(0, 153, 255, 0.80);
  --color-shadow-field-default-active: rgba(0, 153, 255, 0.80);
  --color-shadow-field-success-focus: rgba(52, 232, 172, 0.80);
  --color-shadow-field-success-active: rgba(52, 232, 172, 0.80);
  --color-shadow-field-warning-focus: rgba(242, 201, 76, 0.80);
  --color-shadow-field-warning-active: rgba(242, 201, 76, 0.80);
  --color-shadow-field-alert-focus: rgba(255, 68, 67, 0.80);
  --color-shadow-field-alert-active: rgba(255, 68, 67, 0.80);
  /* Dark */
  /* colors */
  --bg-border-primary: #4b4b4b;
  --bg-border-secondary: #3d3d3d;
  --bg-border-tertriary: #262626;
  --bg-link-link: #0078d2;
  --bg-main-body: #121212;
  --bg-main-brand: #66cc33;
  --bg-main-default: #2c2d2e;
  --bg-main-primary: #2e2e2e;
  --bg-main-quaternary: #2f2f2f;
  --bg-main-secondary: #1d1d1d;
  --bg-main-select: #2e2e2e;
  --bg-main-tertriary: #262626;
  --bg-shadow-shadow-1: #00000014;
  --bg-split-ghost: #e0e0e01a;
  --bg-split-paranja: #0c111366;
  --bg-split-soft: #fafafae5;
  --bg-split-stripe: #fafafa0d;
  --bg-split-tone: #0c1113d9;
  --bg-status-alert: #592121;
  --bg-status-caution: #57481d;
  --bg-status-critical: #410e1d;
  --bg-status-normal: #32522c;
  --bg-status-success: #2c5246;
  --bg-status-system: #4b4b4b;
  --bg-status-warning: #55360d;
  --bg-table-primary: #1d1d1d;
  --bg-table-secondary: #2e2e2e;
  --bg-table-tetriary: #383838;
  --card-bg-default: #262626;
  --card-bg-hover: #333333;
  --control-clear-bg-bg: #12121200;
  --control-clear-bg-bg-alert-hover: #2a1f1f;
  --control-clear-bg-bg-hover: #e5e5e50d;
  --control-clear-bg-bg-select: #345623;
  --control-clear-bg-bg-success-hover: #242e1f;
  --control-clear-typo-typo: #e0e0e0;
  --control-clear-typo-typo-alert: #da3535;
  --control-clear-typo-typo-alert-hover: #da3535;
  --control-clear-typo-typo-alert-succcess: #66cc33;
  --control-clear-typo-typo-hover: #fafafa;
  --control-clear-typo-typo-select: #66cc33;
  --control-clear-typo-typo-succeess: #66cc33;
  --control-default-bg-bg: #212121;
  --control-default-border-bg-border-active: #448026;
  --control-default-border-bg-border-success: #34e8ac;
  --control-default-border-bg-border-success-hover: #27e292;
  --control-default-border-bg-border-warning: #f2c94c;
  --control-default-border-bg-border-warning-hover: #ffc000;
  --control-default-border-border: #3e3e3f;
  --control-default-border-border-alert: #973a39;
  --control-default-border-border-alert-hover: #f01616;
  --control-default-border-border-hover: #464647;
  --control-default-typo-typo: #e0e0e0;
  --control-default-typo-typo-2: #55aa22;
  --control-default-typo-typo-placeholder: #ffffff59;
  --control-disable-bg-bg: #353535;
  --control-disable-border-border: #fafafa00;
  --control-disable-typo-typo: #676767;
  --control-ghost-bg-bg: #20202000;
  --control-ghost-bg-bg-hover: #202020;
  --control-ghost-bg-bg-select: #404040;
  --control-ghost-border-border: #404040;
  --control-ghost-border-border-hover: #404040;
  --control-ghost-border-border-select: #404040;
  --control-ghost-typo-typo: #e0e0e0;
  --control-ghost-typo-typo-hover: #e0e0e0;
  --control-ghost-typo-typo-select: #e0e0e0;
  --control-primary-bg-bg: #2f8c00;
  --control-primary-bg-bg-alert: #cc3e3d;
  --control-primary-bg-bg-alert-hover: #e54645;
  --control-primary-bg-bg-hover: #37a600;
  --control-primary-typo-typo: #fafafa;
  --control-primary-typo-typo-hover: #fafafa;
  --control-secondary-bg-bg: #353535;
  --control-secondary-bg-bg-hover: #4d4d4d;
  --control-secondary-typo-typo: #53b224;
  --control-secondary-typo-typo-hover: #5fcc29;
  --point-card-bg-alert-active: #1e1919;
  --point-card-bg-default: #2f2f2f;
  --point-card-bg-default-active: #222222;
  --point-card-bg-success-active: #1d211b;
  --point-card-bg-warning-active: #1d1a16;
  --point-card-border-alert: #803a39;
  --point-card-border-default: #2e2e2e;
  --point-card-border-success: #238c6f;
  --point-card-border-warning: #a66611;
  --schema-alert-active: #b24745;
  --schema-alert-default: #803331;
  --schema-alert-hover: #993d3b;
  --schema-caution-active: #ccaa3e;
  --schema-caution-default: #997f2e;
  --schema-caution-hover: #b29436;
  --schema-coffee-active: #bf8256;
  --schema-coffee-default: #8c5f3f;
  --schema-coffee-hover: #a6714b;
  --schema-default-active: #666666;
  --schema-default-default: #333333;
  --schema-default-hover: #4c4c4c;
  --schema-disposable-active: #a6a6a6;
  --schema-disposable-default: #8c8c8c;
  --schema-disposable-hover: #999999;
  --schema-primary-active: #12a4b2;
  --schema-primary-default: #0d7580;
  --schema-primary-hover: #0f8d99;
  --schema-secondary-active: #cc5563;
  --schema-secondary-default: #99545a;
  --schema-secondary-hover: #b26269;
  --schema-success-active: #00b277;
  --schema-success-default: #008055;
  --schema-success-hover: #009966;
  --schema-water-active: #148ebf;
  --schema-water-default: #0e688c;
  --schema-water-hover: #117ba6;
  --scrollbar-bg-active: #535555;
  --scrollbar-bg-default: #202020;
  --storage-card-bg-coffee-active: #6f4e37;
  --storage-card-bg-coffee-default: #1d1d1d;
  --storage-card-bg-coffee-hover: #332419;
  --storage-card-bg-default-active: #4b4b4b;
  --storage-card-bg-default-default: #1d1d1d;
  --storage-card-bg-default-hover: #2d2d2d;
  --storage-card-bg-disposable-active: #606060;
  --storage-card-bg-disposable-default: #1d1d1d;
  --storage-card-bg-disposable-hover: #303030;
  --storage-card-bg-primary-active: #107983;
  --storage-card-bg-primary-default: #1d1d1d;
  --storage-card-bg-primary-hover: #112b2e;
  --storage-card-bg-secondary-active: #955c61;
  --storage-card-bg-secondary-default: #1d1d1d;
  --storage-card-bg-secondary-hover: #322425;
  --storage-card-bg-water-active: #15759b;
  --storage-card-bg-water-default: #1d1d1d;
  --storage-card-bg-water-hover: #122a34;
  --storage-card-typo-coffee-active: #ffffff;
  --storage-card-typo-coffee-default: #6f4e37;
  --storage-card-typo-coffee-disable: #6f4e37;
  --storage-card-typo-coffee-hover: #6f4e37;
  --storage-card-typo-default-active: #ffffff;
  --storage-card-typo-default-default: #c6c6c6;
  --storage-card-typo-default-disable: #c6c6c6;
  --storage-card-typo-default-hover: #c6c6c6;
  --storage-card-typo-disposable-active: #ffffff;
  --storage-card-typo-disposable-default: #808080;
  --storage-card-typo-disposable-disable: #808080;
  --storage-card-typo-disposable-hover: #808080;
  --storage-card-typo-primary-active: #ffffff;
  --storage-card-typo-primary-default: #107983;
  --storage-card-typo-primary-disable: #107983;
  --storage-card-typo-primary-hover: #107983;
  --storage-card-typo-secondary-active: #ffffff;
  --storage-card-typo-secondary-default: #955c61;
  --storage-card-typo-secondary-disable: #955c61;
  --storage-card-typo-secondary-hover: #955c61;
  --storage-card-typo-water-active: #ffffff;
  --storage-card-typo-water-default: #15759b;
  --storage-card-typo-water-disable: #15759b;
  --storage-card-typo-water-hover: #15759b;
  --typo-link-link: #0f9fff;
  --typo-link-link-hover: #57bcff;
  --typo-link-link-minor: #6fa5c8;
  --typo-main-brand: #66cc33;
  --typo-main-ghost: #e0e0e04d;
  --typo-main-primary: #e0e0e0;
  --typo-main-secondary: #707070;
  --typo-status-alert: #ff4443;
  --typo-status-caution: #f8c735;
  --typo-status-critical: #da0b49;
  --typo-status-normal: #66cc33;
  --typo-status-success: #11d592;
  --typo-status-system: #d0d0d0;
  --typo-status-warning: #f38b01;
}
/* stylelint-enable */
