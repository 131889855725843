$gridCell-borderRadius: 2px;

.gridCell {
  border-radius: $gridCell-borderRadius;
}

.backgroundCaution {
  background-color: var(--schema-caution-default);
}

.backgroundAlert {
  background-color: var(--schema-alert-default);
}

.backgroundSuccess {
  background-color: var(--schema-success-default);
}
