.MachineProductCalibrationModal {

  .card {
    display: flex;
    padding: var(--space-m) var(--space-l);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--space-m);
    align-self: stretch;

    border-radius: var(--control-radius-round-m);
    background: var(--bg-main-tertriary);
  }

  .ingredientInfo {
    width: 100%;
  }

  .config {
    width: 540px;
    display: grid;
    justify-items: stretch;
    grid-template-columns: repeat(2, 1fr);

    gap: var(--space-s)
  }

  .cellNumberCard {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: var(--control-radius-l);

    background: var(--bg-main-quaternary);

    .text {
      color: var(--typo-color)
    }
  }
}