$MachineListMinWidth: 1100px;
$bgColor: var(--bg-table-primary);
$MachineListTableRow-height: 80px;
$buttonInfoWidth: 22px;
$buttonInfoHeight: 20px;
$connectionStatusSize: 6px;
$serialNumberTextWidth: 110px;

.MachineList {
  flex: 1;
  flex-direction: column;
  gap: var(--space-m);
  min-width: $MachineListMinWidth;

  display: flex;

  .verticalContainer {
    flex: 1;

    .buttonInfo {
      width: $buttonInfoWidth;
      height: $buttonInfoHeight;
      color: var(--typo-main-secondary);
    }

    .iconLock {
      color: var(--control-disable-typo-typo);
    }

    .connectionStatus {
      margin: var(--space-3xs);
      border-radius: 100%;
      min-width: $connectionStatusSize;
      height: $connectionStatusSize;
    }

    .connectionStatus_online {
      background-color: var(--bg-main-brand);
    }

    .iconNewCheck {
      color: var(--typo-main-secondary);
    }

    .connectionStatus_offline {
      background-color: var(--control-disable-bg-bg);
    }
  }


  .machineInfo {
    max-width: 100%;
  }

  .modelInfo {
    max-width: 100%;
  }

  .serialNumberText {
    white-space: nowrap;
    // Это значение подобрано для стандартного размера серийного номера автомата
    min-width: $serialNumberTextWidth;
  }

  .statusInfo {
    max-width: 100%;

    .statusTextContainer {
      width: 100%;
    }
  }
}


.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
