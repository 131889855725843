$QrKeyModal-card--width: 400px;

.QrKeyModal {

  .card {
    width: $QrKeyModal-card--width;
    padding: var(--space-5xl) var(--space-l);

    border-radius: var(--control-radius-round-m);
    background: var(--bg-main-tertriary);

    .qrContainer {
      border-radius: 12px;
      overflow: hidden;
    }
  }
}
