.ClassicTableColumns {
  background-color: var(--bg-main-primary);

  .cell {
    height: 64px;
    border-bottom: 1px solid var(--bg-split-ghost);
    padding: 0 0;

    &:first-child {
      padding-left: var(--space-2xl);
    }

    &:last-child {
      padding-right: var(--space-2xl);
    }

    &.alignLeft {
      text-align: start;
    }

    &.alignRight {
      text-align: end;
    }

    &.fullWidth {
      width: 100%;
    }
  }
}

.checkboxContainer {
  min-width: var(--checkboxContainer-width);
}

.laptop {
  .cell {
    height: 55px;
  }

  &:first-child {
    padding-left: var(--space-xl);
  }

  &:last-child {
    padding-right: var(--space-xl);
  }
}
