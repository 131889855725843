$logCardHeight: 66px;
$MachineHomePageLogsMinHeight: 200px;

.MachineHomePageLogs {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: var(--space-s);
  min-height: $MachineHomePageLogsMinHeight;

  display: flex;

  overflow-y: auto;

  box-sizing: border-box;
  padding: var(--space-m) var(--space-xl) var(--space-xl) var(--space-xl);

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.MachineHomePageLogs::-webkit-scrollbar {
  display: none;
}
