.MachineSnackFillingTable {
  border-radius: var(--control-radius-round-m);
  width: 100%;
  padding: 0;

  .table {
    border: 0;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
  }
}
