$laptop-width: 232px;
$desktopLogo-width: 170px;
$laptopLogo-width: 142px;
$iconSize: 20px;

.LeftMenu {
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  display: flex;

  margin-right: var(--space-3xl);
  margin-bottom: var(--space-3xl);
  border: 1px solid var(--bg-border-primary);
  border-radius: var(--control-radius-round-m);
  box-sizing: border-box;
  max-width: $laptop-width;
  padding: var(--space-4xl) var(--space-m);

  background-color: var(--bg-main-body);

  .logoWrapper {
    justify-content: center;

    display: flex;

    margin-bottom: var(--space-4xl);

    .logo {
      width: $laptopLogo-width;
      height: auto;

      color: var(--typo-main-brand);
    }
  }

  .groupList {
    flex-direction: column;
    gap: var(--space-2xs);

    display: flex;
  }
}

.icon {
  width: $iconSize;
  height: $iconSize;
}
