$contentWidth: 375px;

.configHome {
  display: flex;
  align-items: flex-start;
  gap: var(--space-5xl);

  .buttonsContainer {
    display: flex;
    width: $contentWidth;
    padding: var(--space-5xl);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-l);

    // TODO: заменить радиус
    border-radius: var(--control-radius);
    // TODO: заменить цвет
    border: 1px solid var(--bg-border-primary);
  }
}