$logCardHeight: 66px;

.MachineHomePageLog {
  border: 1px solid var(--bg-border-tertriary);
  border-radius: var(--control-radius-round-m);
  box-sizing: border-box;
  max-height: $logCardHeight;
  padding: var(--space-m);

  background: var(--bg-main-tertriary);

  .text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .icon {
    min-width: 24px;
  }

  .successIcon {
    color: var(--typo-status-success)
  }

  .warningIcon {
    color: var(--typo-status-warning)
  }

  .alertIcon {
    color: var(--typo-status-alert)
  }
}