.productList {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
  width: 100%;
  margin-bottom: var(--space-2xl);

  .table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  .addButton {
    width: 100%;
    justify-content: center;
  }
}