$numberCell-width: 22px;
$textField-width: 38px;
$volumeCell-width: 87px;
$brandCell-width: 146px;
$MachineSnackRefillTableRow-height: 32px;
$text-padding: 6px;

.MachineSnackRefillTableRow {
  align-items: center;
  column-gap: var(--space-s);

  display: flex;

  border-bottom: 1px solid var(--bg-border-secondary);
  box-sizing: border-box;
  width: 100%;
  height: $MachineSnackRefillTableRow-height;
  padding: var(--space-2xs) 0;

  .cell {
    box-sizing: border-box;
    padding: 0;
  }

  .cellNumberCell {
    flex-shrink: 0;

    width: $numberCell-width;

    text-align: center;
  }

  .brandCell {
    flex-shrink: 0;
  }

  .productCell {
    flex-grow: 1;

    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .volumeCell {
    flex-shrink: 0;

    width: $volumeCell-width;

    text-align: right;

    .textField {
      width: $textField-width;
    }

    .text {
      box-sizing: border-box;
      padding: $text-padding;
    }
  }
}

.MachineSnackRefillTableRow:last-child {
  border-bottom: none;
}
