$memberCard-height: 70px;
$memberCard-width: 470px;

$avatar-size: 40px;

.AddKeys {

  .memberCard {
    width: $memberCard-width;
    min-height: $memberCard-height;

    padding: 0 var(--space-l);

    border-radius: var(--control-radius-round-s);
    background: var(--bg-main-tertriary);
  }
}

.text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.avatar {
  width: $avatar-size;
  height: $avatar-size;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
