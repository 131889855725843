$nameField-width: 340px;
$contactField-width: 200px;

.nameField {
  width: $nameField-width;
}

.contactField {
  width: $contactField-width;
}
