.MemberKeyActive {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--space-l);
  color: var(--typo-main-primary);
  fill: var(--typo-main-primary);
  flex: 0;

  .activeCard {
    padding: var(--space-m) var(--space-l);

    border-radius: var(--control-radius-round-m);
    background: var(--bg-main-tertriary);

    width: 50px;

    border: 1px solid var(--bg-border-tertriary);

    .text {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .lastSaleDate {
    width: auto;
    flex: 1;
  }
}

.iconCalendar {
  --icon-fill-color: var(--control-clear-bg-bg);
  --icon-stroke-color: var(--typo-main-primary);
}

.laptop {
  gap: var(--space-s);

  .activeCard {
    padding: var(--space-s) var(--space-m);
  }
}
