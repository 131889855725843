$MachineHomePageStatusHeight: 148px;
$statusCardHeight: 68px;

.MachineHomePageStatus {
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: var(--space-m);

  display: flex;

  height: $MachineHomePageStatusHeight;
  padding: calc(var(--space-m) - 1px) var(--space-xl) var(--space-xl) var(--space-xl);

  .statusCard {
    justify-content: space-between;

    border: 1px solid var(--bg-border-tertriary);
    border-radius: var(--control-radius-round-m);
    box-sizing: border-box;
    height: $statusCardHeight;
    padding: var(--space-s) var(--space-l);

    background: var(--bg-main-tertriary);

  }
}
