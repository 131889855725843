$bgColor: var(--bg-table-primary);
$tableItem-minHeight: 62px;

.tableBrandItem {
  display: flex;
  padding: 0 var(--space-3xl);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 24px;
  background-color: $bgColor;

  .brand {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: $tableItem-minHeight;
    cursor: pointer;

    .brandInfo {
      display: flex;
      flex-direction: row;
      gap: var(--space-xs);
      align-items: center;
    }
  }
}

.brandContextMenu {
  background-color: $bgColor;
}
