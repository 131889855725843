$columnTitle: 24px;

.TableHeader {

  .TableColumns th {
    background-color: var(--bg-main-secondary);
  }

  .columnTitle {
    padding: 0 var(--space-xs);
    width: 10%;
    text-align: start;

    height: $columnTitle;
    background-color: var(--bg-main-secondary);

    .buttonSort {
      width: var(--control-height-xs);
      height: var(--control-height-xs);

      .iconSort {
        color: var(--typo-main-secondary);
      }
    }

    &:first-child {
      padding-left: var(--space-m);
    }

    &:last-child {
      padding-right: var(--space-s);
    }

    &.fullWidth {
      width: 100%;
    }
  }
}