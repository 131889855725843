$default-info-width: 504px;
$default-sidebar-width: 584px;
$value-container-width: 250px;
$name-container-width: 244px;
$infoItem-height: 20px;

.defaultInfo {
  width: $default-info-width;
  gap: var(--space-s);
}

.sidebar {
  width: $default-sidebar-width;
}

.infoContainer {
  align-items: flex-start;

  border-bottom: none;
}

.valueContainer {
  width: $value-container-width;
}

.nameContainer {
  width: $name-container-width;
  border-bottom: 1px dotted var(--bg-border-primary);

  height: $infoItem-height;
  box-sizing: border-box;
}
