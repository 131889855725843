$maxValue-width: 67px;

.ingredient {
  display: flex;
  flex-direction: column;
  gap: var(--space-l);

  border-bottom: 1px dashed var(--bg-border-primary);
  padding-bottom: var(--space-2xl);
}

.ingredient:last-child{
  border-bottom: none;
  padding-bottom: 0;
}

.ingredientRow {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: flex-end;

  .requiredSwitch {
    height: var(--control-height-l);
    display: flex;
    align-items: center;
  }

  .maxValue {
    width: $maxValue-width;
  }
}

