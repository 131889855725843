$padding-left: 8px;
$padding: 64px;
$margin-top: 44px;
$maxWidth: 1920px;

.DefaultFullScreenFilters {
  top: 0;
  align-items: center;
  flex-direction: column;

  display: flex;

  overflow-y: auto;

  box-sizing: border-box;
  min-width: 100%;
  min-height: 100%;
  padding: var(--space-m) 0 var(--space-3xl) $padding-left;

  background: var(--bg-main-body);


  .horizontalContainer {
    width: 100%;
    padding-right: $padding-left;
  }

  .verticalContainer {
    min-width: 1440px;
    //width: 100%;
    max-width: $maxWidth;

    .horizontalContainerTitle {
      justify-content: space-between;

      margin-top: $margin-top;
      padding: 0 $padding
    }

    .children {
      padding: 0 $padding;
    }
  }
}