$bgColor: var(--bg-table-primary);

.table {
  margin-top: 0;
  padding-left: var(--space-l);
}

.machineContextMenu {
  background-color: $bgColor;
}

.connectionStatus {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.iconNewCheck {
  color: var(--typo-main-secondary);
}

.connectionStatus_online {
  background-color: var(--bg-main-brand);
}

.connectionStatus_offline {
  background-color: var(--control-disable-bg-bg);
}

.text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
