$padding-left: 8px;

.defaultFullScreenModal {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  scrollbar-gutter: stable;
  top: 0;
  padding: var(--space-3xl) 0 var(--space-3xl) $padding-left;
  background: var(--bg-main-body);
  min-height: 100%;
  max-width: 100%;
  width: 100%;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: var(--space-l);

    .breadcrumbsContainer {
      display: flex;
      flex-direction: column;

      .breadcrumbs {
        height: 20px;

        li:not(:last-child):hover span {
          color: var(--typo-main-brand);
        }

        li:not(:last-child):hover a {
          color: var(--typo-main-brand);
        }
      }
    }
  }

  .content {
    margin-top: var(--space-2xl);
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--space-l);
  }
}
