$memberInfo-width: 384px;

.ChangeMemberPassword {
  .memberInfo {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 0;

    border: 1px solid var(--bg-border-tertriary);
    border-radius: var(--control-radius-round-s);
    width: $memberInfo-width;

    background-color: var(--bg-main-tertriary);
  }
}
