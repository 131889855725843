.gridCell {
  align-items: center;
  justify-content: center;

  display: flex;

  border-radius: var(--control-radius-xs);
  border: 1px solid var(--bg-border-primary);

  text-align: center;
}

.backgroundCaution {
  background-color: var(--schema-caution-default);
}

.backgroundAlert {
  background-color: var(--schema-alert-default);
}

.backgroundSuccess {
  background-color: var(--schema-success-default);
}
