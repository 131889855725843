.formItem {
  display: flex;
  flex-direction: column;
  gap: var(--space-2xl);

  width: calc(100% - 2 * var(--space-l));

  padding: var(--space-l);

  border-radius: var(--control-radius-round-m);
  background-color: var(--bg-main-tertriary);

  // TODO: тут заменить тень и цвет на константы
  box-shadow: 0 2px 20px 0 rgba(28, 28, 28, 0.05);

  .header {
    display: flex;
    justify-content: space-between;

    .actions {
      display: flex;
      gap: var(--space-l)
    }
  }
}

.selectStep {
  background-color: var(--bg-main-select);
}
