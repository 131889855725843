.OutletMachineListStorageCell {
  height: 50px;

  .waterCell {
    color: var(--storage-card-typo-water-default);

    .text {
      color: var(--storage-card-typo-water-default);
    }
  }

  .cupCell {
    color: var(--storage-card-typo-disposable-default);

    .text {
      color: var(--storage-card-typo-disposable-default);
    }
  }

  .iconLock {
    color: var(--control-disable-typo-typo);
  }
}