$ContentCardHeaderHeight: 28px;

.MachineSnackRefillTableWrapper {
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .ContentCard {
    align-items: flex-start;
    gap: 0;
    display: flex;
    border-radius: var(--control-radius-round-m);
    box-sizing: border-box;
    padding: var(--space-s) var(--space-m) var(--space-m) var(--space-m);

    .ContentCardHeader {
      align-items: flex-start;
      display: flex;
      box-sizing: border-box;
      height: $ContentCardHeaderHeight;
      padding: var(--space-2xs) 0;
    }
  }
}
