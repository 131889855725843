$documentForm-width: calc(586px - 2 * var(--space-xl));
$documentForm-padding: var(--space-xl);


.documentForm {
  width: $documentForm-width;
  padding: $documentForm-padding;
  display: flex;
  flex-direction: column;
  gap: var(--space-xl);

  background-color: var(--bg-main-primary);

  .actions {
    display: flex;
    justify-content: flex-end;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: var(--space-m);
  }
}