$storageSchemeGroupHeight: 253px;
$storageSchemeGroupHeightIsRefill: 256px;
$horizontalMinWidth: 200px;
$horizontalHeight: 48px;
$disposableMinWidth: 100px;

.storageSchemeGroup {
  gap: var(--space-xs);
  justify-content: center;
  flex-direction: column;
  align-items: center;

  display: flex;

  border: 1px solid var(--bg-border-tertriary);
  border-radius: var(--control-radius-round-m);
  box-sizing: border-box;
  width: calc(100% - 2 * var(--space-l));
  height: $storageSchemeGroupHeight;
  padding: var(--space-m);

  &.isRefill {
    width: 100%;
    height: $storageSchemeGroupHeightIsRefill;
    gap: var(--space-s);
  }

  background-color: var(--bg-main-secondary);

  .cellsContainer {
    flex-direction: row;
    justify-content: center;
    gap: var(--space-s);

    display: flex;

    height: 100%;

    &.isRefill {
      gap: var(--space-m);
    }
  }
}

.horizontalGroup {
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--space-l);

  display: flex;

  .horizontal {
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;

    min-width: $horizontalMinWidth;
    width: auto;
    height: $horizontalHeight;
    padding: 0 var(--space-l);
  }

  .isRefill {
    padding: 0 var(--space-m);
  }
}

.text {
  &.PRIMARY {
    color: var(--storage-card-typo-primary-default);
  }

  &.SECONDARY {
    color: var(--storage-card-typo-secondary-default);
  }

  &.COFFEE {
    color: var(--storage-card-typo-coffee-default);
  }

  &.DEFAULT {
    color: var(--storage-card-typo-default-default);
  }
}
