.storageCard {
  display: flex;

  flex-direction: column;

  gap: var(--space-m);

  .cardInfo {
    display: flex;

    flex-direction: row;

    gap: var(--space-xs);

    height: 32px;

    cursor: pointer;
  }

  .cardForm {
    flex: 1;
    background-color: var(--bg-color-hover);

    padding: var(--space-l);

    border-radius: var(--space-l);
  }

  .card {
    display: flex;

    justify-content: center;
    align-items: center;

    width: 100%;

    border-radius: var(--control-radius-xs);
    background-color: var(--bg-color);

    .text {
      color: var(--typo-color);
      display: flex;
      align-items: center;

      //white-space: nowrap;
      //overflow: hidden;
      //text-overflow: ellipsis;
    }

    .icon {
      fill: var(--typo-color);
    }
  }

  .hoverCard {
    background-color: var(--bg-color-hover);

    .text {
      color: var(--typo-color-hover);
    }

    .icon {
      fill: var(--typo-color-hover);
    }
  }

  .activeCard {
    background-color: var(--bg-color-active);

    .text {
      color: var(--typo-color-active);
    }

    .icon {
      fill: var(--typo-color-active);
    }
  }

  .leftSide {
    width: 32px;
  }

  .rightSide {
    width: 120px;
  }

  .refillFieldWrapper {
    display: flex;

    gap: 8px;

    .field {
      width: 67px;
    }
  }
}

.horizontalFormContainer {
  display: flex;
  flex-direction: row;
  gap: var(--space-s);
}

.verticalFormContainer {
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
}

.blocked {
  // TODO: тут замена цветов на константы
  .card {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid var(--bg-border-tertriary);
  }

  .hoverCard {
    background-color: var(--bg-color-hover);

    .text {
      color: var(--typo-color-hover);
    }

    .icon {
      fill: var(--typo-color-hover);
    }
  }

  .activeCard {
    background-color: var(--bg-color-active);

    .text {
      color: var(--typo-color-active);
    }

    .icon {
      fill: var(--typo-color-active);
    }
  }
}

.alert {
  .card {
    background-color: var(--bg-status-alert);

    .text {
      color: var(--storage-card-typo-primary-active);
    }

    .icon {
      fill: var(--storage-card-typo-primary-active);
    }
  }
}
