$UserSettingBorderRadius: 100px;
$UserSettingWidth: 362px;
$UserSettingHeight: 52px;
$UserSettingMobileWidth: 84px;
$UserSettingMobileHeight: 40px;
$avatarMinWidth: 36px;
$avatarHeight: 36px;
$avatarContainerWidth: 96px;
$avatarContainerHeight: 96px;
$iconMinWidth: 24px;
$iconHeight: 24px;
$textWidth: 250px;
$mobileAvatarSize: 28px;
$dropDownMenuMobileWidth: 362px;

.UserSetting {
  align-items: center;
  gap: var(--space-s);

  display: flex;

  border: 1px solid var(--bg-border-primary);
  border-radius: $UserSettingBorderRadius;
  width: $UserSettingWidth;
  height: $UserSettingHeight;
  padding: 0 calc(var(--space-l) - 1px) 0 calc(var(--space-xs) - 1px);

  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;

  &.isMobile {
    align-items: center;
    gap: var(--space-s);

    display: flex;

    width: $UserSettingMobileWidth;
    height: $UserSettingMobileHeight;
    padding: var(--space-xs) var(--space-m) var(--space-xs) var(--space-xs);
  }

  &:hover {
    background-color: var(--control-clear-bg-bg-hover);
  }

  .icon {
    color: var(--typo-main-primary);
    min-width: $iconMinWidth;
    height: $iconHeight;
  }

  .avatar {
    align-content: center;

    min-width: $avatarMinWidth;
    height: $avatarHeight;

    font-size: var(--size-text-l);
    text-align: center;

    &.isMobile {
      min-width: $mobileAvatarSize;
      height: $mobileAvatarSize;

      font-size: var(--size-text-m);
    }

  }

  .text {
    display: inline-block;

    overflow: hidden;

    width: $textWidth; /* ширина строки */

    white-space: nowrap;
    text-overflow: ellipsis;
    letter-spacing: .07em;
  }
}

.isOpenMenu {
  background: var(--control-ghost-bg-bg-select);

  &:hover {
    background: var(--control-ghost-bg-bg-select);
  }
}

.dropDownMenu {
  border-radius: var(--control-radius-round-m);
  border: 1px solid var(--bg-border-primary);

  background-color: var(--bg-main-body);

  &.isMobile {
    left: auto !important;
    right: var(--space-3xl) !important;

    width: $dropDownMenuMobileWidth !important;
    max-height: 70% !important;
  }

  .dropDownMenuContainer {
    margin: calc(var(--space-xs) + var(--space-2xl)) var(--space-l) var(--space-xs);
    width: auto;

    &.isMobile {
      width: $dropDownMenuMobileWidth;
    }

    .avatarContainer {
      justify-content: center;
      align-items: center;

      display: flex;

      overflow: hidden;

      border-radius: 100%;
      width: $avatarContainerWidth;
      height: $avatarContainerHeight;

      font-size: var(--size-text-3xl);
    }
  }

  .organizationTag {
    align-items: center;
    gap: var(--space-xs);

    display: flex;

    border-radius: var(--control-radius-round-l);
    padding: var(--space-2xs) var(--space-xs);

    background-color: var(--bg-main-secondary);
  }
}
