.MenuItem {
  display: flex;
  padding: var(--space-m) var(--space-2xl);
  align-items: center;

  border-radius: var(--control-radius-round-xs);
  border-color: rgba(0, 0, 0, 0);
  background-color: var(--control-clear-bg);

  color: var(--control-clear-typo-typo);

  cursor: pointer;

  &:hover {
    background-color: var(--control-clear-bg-bg-hover);
    color: var(--control-clear-typo-typo-hover)
  }
}

.isSmall {
  padding: var(--space-xs) var(--space-m);
}

.isActive {

  background-color: var(--control-clear-bg-bg-hover);
}
