.modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 0;

  padding: var(--space-2xl) var(--space-l) var(--space-l) var(--space-l);

  background-color: var(--bg-main-primary);
  border-radius: var(--control-radius-round-s);
}

.filter {

  &.select {
    background-color: var(--control-ghost-bg-bg-select);
    border-color: var(--control-ghost-border-border-select);
    color: var(--control-ghost-typo-typo-select);

    &:hover {
      background-color: var(--control-ghost-bg-bg-select) !important;
      border-color: var(--control-ghost-border-border-select) !important;
      color: var(--control-ghost-typo-typo-select) !important;
    }
  }
}
