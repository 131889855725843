.TabsBadge {
  .skeleton-xs {
    border-radius: var(--control-radius-xs);
  }

  .skeleton-s {
    border-radius: var(--control-radius-s);
  }

  .skeleton-m {
    border-radius: var(--control-radius-m);
  }
}
