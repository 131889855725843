$spacerWidth: 12px;
$spacerHeight: 14px;
$LeftMenuItemHeight: 28px;
$borderRadius: 4px;

.HorizontalContainer {
  position: relative;

  .leftLine {
    border-left: 1px solid var(--control-default-border-border);

    .spacer {
      position: absolute;
      left: 0;

      border-left: 1px solid var(--control-default-border-border);
      border-bottom: 1px solid var(--control-default-border-border);
      border-radius: 0 0 0 $borderRadius;
      width: $spacerWidth;
      height: $spacerHeight;
    }

    .LeftMenuItem {
      align-items: center;
      flex: 1;

      display: flex;

      margin-left: calc($spacerWidth + var(--space-xs));
      border-radius: var(--control-radius-s);
      height: $LeftMenuItemHeight;
      padding: 0 var(--space-m);

      background: var(--bg-main-secondary);
      cursor: pointer;

      color: var(--typo-main-secondary);

      &:hover {
        background: var(--control-clear-bg-bg-hover);

        color: var(--control-clear-typo-typo-hover);

        .text {
          color: var(--control-clear-typo-typo-hover);
        }
      }

      &.select {
        background: var(--control-clear-bg-bg-select);

        color: var(--control-clear-typo-typo-select);

        .text {
          color: var(--control-clear-typo-typo-select);
        }
      }
    }
  }

  &:last-of-type {
    .leftLine {
      border-color: transparent !important;
    }
  }
}
