$cell-height: 37px;
$cell-height-laptop: 25px;
$header-padding: 9px;
$header-padding-laptop: 11px;

.MachineFillingInfoTable {
  width: 100%;

  padding-bottom: var(--space-l);

  .table {
    border: 0;
    border-spacing: 0;
    width: 100%;

    .row {
      .cell {
        text-align: start;
      }

      .cellNumberCell {
        box-sizing: border-box;

        width: 6%;
        text-align: start;
        padding-left: 6px;
      }

      .brandCell {
        width: 28%;
      }

      .ingredientLineCell {
        width: 27.5%;
      }

      .ingredientCell {
        width: 28.5%;
      }

      .volumeCell {
        width: 13%;
        text-align: end;
      }
    }

    .head {
      .row {
        .cell {
          height: $cell-height;
          //padding: 0 0 0 var(--space-xs);
        }

        .cellNumberCell {
          padding-left: 0;
          text-align: start;
        }
      }
    }

    .body {

      .row {
        &:first-child {
          .cell {
            padding-top: $header-padding;
          }
        }

        &:last-child {
         .cell {
           border: 0;
         }
        }

        &.hover {
          &.PRIMARY {
            background-color: var(--storage-card-bg-primary-hover);

            .text {
              color: var(--storage-card-typo-primary-hover);
            }

            .volumeText {
              color: var(--typo-main-secondary)
            }
          }

          &.SECONDARY {
            background-color: var(--storage-card-bg-secondary-hover);

            .text {
              color: var(--storage-card-typo-secondary-hover);
            }

            .volumeText {
              color: var(--typo-main-secondary)
            }
          }

          &.COFFEE {
            background-color: var(--storage-card-bg-coffee-hover);

            .text {
              color: var(--storage-card-typo-coffee-hover);
            }

            .volumeText {
              color: var(--typo-main-secondary)
            }
          }

          &.DEFAULT {
            background-color: var(--storage-card-bg-default-hover);

            .text {
              color: var(--storage-card-typo-default-hover);
            }

            .volumeText {
              color: var(--typo-main-secondary)
            }
          }
        }

        &.PRIMARY {
          .text {
            color: var(--storage-card-typo-primary-default);
          }

          .volumeText {
            color: var(--typo-main-secondary)
          }
        }

        &.SECONDARY {
          .text {
            color: var(--storage-card-typo-secondary-default);
          }

          .volumeText {
            color: var(--typo-main-secondary)
          }
        }

        &.COFFEE {
          .text {
            color: var(--storage-card-typo-coffee-default);
          }

          .volumeText {
            color: var(--typo-main-secondary)
          }
        }

        &.DEFAULT {
          .text {
            color: var(--storage-card-typo-default-default);
          }

          .volumeText {
            color: var(--typo-main-secondary)
          }
        }

        .cell {
          height: $cell-height;
          border-bottom: 1px solid var(--bg-split-ghost);
          //padding: 0 0 0 var(--space-xs);
        }
      }
    }
  }
}

.text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.isLaptop {
  &.MachineFillingInfoTable {
    padding-top: var(--space-l);
    padding-bottom: var(--space-m);

    border-radius: var(--control-radius-round-m);

    .table {
      .row {
        .cellNumberCell {
          //box-sizing: border-box;

          width: 7%;
          text-align: start;
          padding-left: 6px;
        }

        .brandCell {
          width: 27%;
        }

        .ingredientLineCell {
          width: 27%;
        }

        .ingredientCell {
          width: 28%;
        }

        .volumeCell {
          width: 13%;
          text-align: end;
        }
      }

      .head {
        .row {
          .cell {
            height: $cell-height-laptop;
          }

          .cellNumberCell {
            padding-left: 0;
          }
        }
      }

      .body {
        .row {
          &:first-child {
            .cell {
              padding-top: $header-padding-laptop;
            }
          }

          .cell {
            height: $cell-height-laptop;
          }
        }
      }
    }
  }
}
