$defaultModal-minWidth: calc(512px - var(--modal-padding-right) - var(--modal-padding-left));
$defaultModal-minHeight: 100px;
$headerHeight: 30px;
// TODO: тут сложности реализации. Как-то нужно сделать, что-бы отступ снизу и сверху модального окна был 100
//  Пока что установлен фиксированный размер. Нужно немного тестов, возможно придётся залезть в компонент модалки
$contentModal-maxHeight: 800px;
$defaultModal-top: 60px;

.defaultModal {
  display: flex;
  min-width: $defaultModal-minWidth;
  min-height: $defaultModal-minHeight;
  padding: var(--modal-padding-top) 0 var(--modal-padding-bottom) 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //gap: var(--modal-gap-modal);
  border-radius: var(--card-radius);
  background-color: var(--bg-main-secondary);
  top: $defaultModal-top;

  &.isMobile {
    max-height: 70% !important;
  }

  .header {
    display: flex;
    height: $headerHeight;
    padding: var(--modal-padding-header-top) calc(var(--modal-padding-right) - 8px) var(--modal-padding-header-bottom) var(--modal-padding-left);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  .content {
    display: flex;
    max-height: $contentModal-maxHeight;
    padding: var(--modal-gap-modal) var(--modal-padding-right) var(--modal-gap-modal) var(--modal-padding-left);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--modal-gap-content);
    align-self: stretch;
    overflow-y: auto;
  }

  .actions {
    display: flex;
    padding-top: var(--modal-padding-action-top);
    padding-left: var(--modal-padding-left);
    padding-right: var(--modal-padding-right);
    justify-content: flex-end;
    align-items: flex-end;
    gap: var(--modal-gap-action);
    align-self: stretch;
  }
}

.isPrint {
  height: 100%;
  width: 100%;
  align-items: start;
  justify-content: start;
  box-shadow: 0 0 0 0 white;
  border: 0;
  border-radius: 0;
}
